import React, { useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './index.css';
import { MainNavigation } from 'components/MainNavigation';
import DashboardPage from 'pages/DashboardPage';
import MyNFTsPage from 'pages/MyNFTsPage/page';
import TiersPage from 'pages/TiersPage/page';
import StakePage from 'pages/StakePage/page';
import { MainContentWrapper } from 'components/MainNavigation/styles';
import { MainUI } from 'components/UI/MainUI';
import useEagerConnect from './hooks/useEagerConnect';
import useEagerFetchData from './hooks/useEagerFetchData';
import history from './routerHistory';

const App: React.FC = () => {
  useEagerConnect();
  useEagerFetchData();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Router history={history}>
      <MainNavigation isOpen={isOpen} setIsOpen={setIsOpen} />
      <MainContentWrapper isMenuOpen={isOpen}>
        <MainUI />
        <Switch>
          <Route path='/dashboard' exact>
            <DashboardPage />
          </Route>
          <Route path='/stake' exact>
            <StakePage />
          </Route>
          <Route path='/my-nfts' exact>
            <MyNFTsPage />
          </Route>
          <Route path='/tiers' exact>
            <TiersPage />
          </Route>

          <Route path='*' exact>
            <Redirect to='/dashboard' />
          </Route>
        </Switch>
      </MainContentWrapper>
    </Router>
  );
};

export default React.memo(App);
