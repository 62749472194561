import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNFTState } from 'state/contract-nft/hooks';
import { MyNFT } from './MyNFT';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import WalletModal from '../../WalletModal';
import { DaoVotingInfo } from './DaoVotingInfo';
import { Status } from '../../../interfaces/statuses';

const PageMyNFT = () => {
  const { account } = useActiveWeb3React();
  const nftState = useNFTState();

  return (
    <Container>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            paddingBottom: 10,
          }}
        >
          {account ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <Typography
                component='h1'
                variant='h3'
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                My NFTs
              </Typography>
              <DaoVotingInfo isLoading={nftState.stateStatus !== Status.SUCCESS} />
              <MyNFT />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                minHeight: '50vh',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <WalletModal />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageMyNFT;
