import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers';
import { Contract } from 'ethers';
import { AddressZero } from '@ethersproject/constants';
import { isAddress } from 'ethers/lib/utils';
import { Nft2 } from 'config/constants/abis/types';
import { connectionConfig } from '../config/constants';
import { ITokenContract } from '../interfaces/token-contract.interface';
import { INFTContract } from '../state/contract-nft/interfaces/contract.interface';
import {
  IStakingContract,
  IStakingContractV2,
} from '../state/contract-staking/interfaces/contract.interface';
import { IMulticallContract } from '../state/contract-multicall/interfaces/contract.interface';

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getOldContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function getMulticallContract(library: Web3Provider, account?: string): IMulticallContract {
  return getOldContract(
    connectionConfig.multicall.address,
    connectionConfig.multicall.abi,
    library,
    account,
  ) as IMulticallContract;
}

export function getNFTContract(library: Web3Provider, account?: string): INFTContract {
  return getOldContract(
    connectionConfig.nft.address,
    connectionConfig.nft.abi,
    library,
    account,
  ) as INFTContract;
}

export function getNFTContractV2(library: Web3Provider, account?: string): Nft2 {
  return getOldContract(
    connectionConfig.nft2.address,
    connectionConfig.nft2.abi,
    library,
    account,
  ) as Nft2;
}

export function getTokenContract(library: Web3Provider, account?: string): ITokenContract {
  return getOldContract(
    connectionConfig.token.address,
    connectionConfig.token.abi,
    library,
    account,
  ) as ITokenContract;
}

export function getStakingContract(library: Web3Provider, account?: string): IStakingContract {
  return getOldContract(
    connectionConfig.staking2.address,
    connectionConfig.staking2.abi,
    library,
    account,
  ) as IStakingContract;
}

export function getStakingContractV2(library: Web3Provider, account?: string): IStakingContractV2 {
  return getOldContract(
    connectionConfig.staking3.address,
    connectionConfig.staking3.abi,
    library,
    account,
  ) as IStakingContractV2;
}
