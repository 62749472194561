import { LoadingBox } from 'components/Common/LoadingBox';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { formatBigNumber } from 'utils/formatStringNumber';
import { TreasuryTableInfo } from '../types';
import { TreasuryTable, TreasuryTD, TreasuryTR, TreasuryValueTitle } from './styles';

const TreasuryReservesArea = styled.div`
  grid-area: reserves;
`;

type TreasuryReservesProps = {
  reserves: TreasuryTableInfo | null;
};

export function TreasuryReserves({ reserves }: TreasuryReservesProps) {
  if (!reserves) return <LoadingTreasuryReserves />;

  const formattedTotalUsd = formatBigNumber(reserves.totalUsd, { fractionalFlooring: 0 });

  return (
    <TreasuryReservesArea>
      <div>
        <TreasuryValueTitle>TREASURY RESERVES</TreasuryValueTitle>
        <TreasuryTable>
          <tbody>
            {reserves.tokens.map((info, i) => (
              <TreasuryTR key={`treasury-reserved-${i}`}>
                <TreasuryTD>{info.symbol}</TreasuryTD>
                <TreasuryTD>{info.tokenAmount}</TreasuryTD>
                <TreasuryTD>$ {info.usdAmount}</TreasuryTD>
              </TreasuryTR>
            ))}
            <TreasuryTR key='treasury-reserved-total'>
              <TreasuryTD>TOTAL</TreasuryTD>
              <TreasuryTD />
              <TreasuryTD>$ {formattedTotalUsd}</TreasuryTD>
            </TreasuryTR>
          </tbody>
        </TreasuryTable>
      </div>
    </TreasuryReservesArea>
  );
}

const loadingReservesCount = 3;
function LoadingTreasuryReserves() {
  const loadingReserves: ReactNode[] = [];
  for (let i = 0; i < loadingReservesCount; i++)
    loadingReserves.push(
      <TreasuryTR key={`treasury-reserved-${i}`}>
        <TreasuryTD>
          <LoadingBox width='5rem' height='1.5rem' />
        </TreasuryTD>
        <TreasuryTD>
          <LoadingBox width='10rem' height='1.5rem' />
        </TreasuryTD>
        <TreasuryTD>
          <LoadingBox width='7rem' height='1.5rem' />
        </TreasuryTD>
      </TreasuryTR>,
    );

  return (
    <TreasuryReservesArea>
      <div>
        <TreasuryValueTitle>TREASURY RESERVES</TreasuryValueTitle>
        <TreasuryTable>
          <tbody>{loadingReserves.map((elem) => elem)}</tbody>
        </TreasuryTable>
      </div>
    </TreasuryReservesArea>
  );
}
