import { useEffect, useState } from 'react';
import { JsonRpcProvider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { networksConfig } from 'config/constants/networks';
import {
  invesmentChainIdList,
  InvestmentChainId,
  INVESTMENT_DATA_URL,
} from 'config/constants/investment';

export type InvestmentTokenSymbolList = { [key: string]: string };

export type FetchedInvestmentData = {
  wethAddress: string;
  usdAddress: string;
  usdDecimals?: number; // default is 18
  router02Address: string;
  tokenList: InvestmentTokenSymbolList;
};
export type InvestmentChainData = FetchedInvestmentData & {
  provider: JsonRpcProvider;
};
export type InvestmentChainDataList = {
  [key in InvestmentChainId]: InvestmentChainData;
};

export function useInvestmentChainDataList() {
  const [invesmentChainDataList, setInvesmentChainDataList] =
    useState<InvestmentChainDataList | null>(null);

  useEffect(() => {
    async function fetchChainData(chainId: InvestmentChainId): Promise<FetchedInvestmentData> {
      const fetchedData = await fetch(`${INVESTMENT_DATA_URL}/${chainId}/investment.json`);
      const json = await fetchedData.json();

      return json as FetchedInvestmentData;
    }

    (async function () {
      const fetchedDatas = await Promise.all(
        invesmentChainIdList.map((chainId) => fetchChainData(chainId)),
      );

      const chainDataList = fetchedDatas.reduce(
        (prev, data, i) => ({
          ...prev,
          [invesmentChainIdList[i]]: {
            ...data,
            provider: new ethers.providers.JsonRpcProvider(
              networksConfig[invesmentChainIdList[i]].rpcUrls[0],
            ),
          },
        }),
        {},
      );

      setInvesmentChainDataList(chainDataList as InvestmentChainDataList);
    })();
  }, []);

  return invesmentChainDataList;
}

// provider: new ethers.providers.JsonRpcProvider(
//   networksConfig[InvestmentChainId.BSC_PROD].rpcUrls[0],
// ),
