export enum InvestmentChainId {
  BSC_PROD = 56,
  ETH_PROD = 1,
}

export const invesmentChainIdList: InvestmentChainId[] = [
  InvestmentChainId.BSC_PROD,
  InvestmentChainId.ETH_PROD,
];

export const INVESTMENT_DATA_URL =
  'https://raw.githubusercontent.com/pro100skm/rebl-data/master/assets';
