import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { toast } from 'react-toastify';
import { formatEther, formatUnits, parseUnits } from 'ethers/lib/utils';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Dispatch } from '@reduxjs/toolkit';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from 'ethers';
import styled from 'styled-components';
import { PrimaryButton } from 'components/Common/Button';
import { ITokenContract } from '../../../interfaces/token-contract.interface';
import { connectionConfig, MAX_UINT256, WEEK_IN_SECONDS } from '../../../config/constants';
import { Status } from '../../../interfaces/statuses';
import useDebounce from '../../../hooks/useDebounce';
import { formatPrice } from '../../../utils/customConverter';
import WalletModal from '../../WalletModal';
import { Balance } from '../../UI/Balance';
import { IWeb3Error } from '../../../interfaces/web3-error.interface';
import { txPending } from '../../../utils/tx-pending';
import { getAllRewards, getMultiplier, getTier } from '../../../utils/calcHelper';
import { IValueError } from '../../../interfaces/value-error.interface';
import { getTimeFromNow } from '../../../utils/getTime';
import { IStakingContract } from '../../../state/contract-staking/interfaces/contract.interface';
import { fetchTokenThunk } from '../../../state/contract-token';
import { fetchStakingThunk } from '../../../state/contract-staking';
import { IStakingStateFormatted } from '../../../state/contract-staking/interfaces/data.interface';
import { ITokenStateFormatted } from '../../../state/contract-token/interfaces/data.interface';
import { updateTokenPartial } from '../../../state/contract-token/actions';
import { StyledStakeCard } from './styles';

const MaxButton = styled.button`
  color: ${({ theme }) => theme.primary1};
  font-size: 1.15rem;

  background: none;
  border: none;

  text-decoration: underline;
  text-decoration-style: dotted;

  margin-left: 1rem;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

interface Props {
  tokenState: ITokenStateFormatted;
  tokenContract: ITokenContract | null;
  stakingState: IStakingStateFormatted;
  stakingContract: IStakingContract | null;
  dispatch: Dispatch<any>;
  account?: string | null;
  library?: Web3Provider;
  loggedIn: boolean;
}

interface IStakeData {
  amount: IValueError<BigNumber>;
  weeks: IValueError<number>;
}

const initialData: IStakeData = {
  amount: { value: BigNumber.from('0'), error: '' },
  weeks: { value: 2, error: '' },
};

export const CardStake = ({
  tokenState,
  tokenContract,
  stakingState,
  stakingContract,
  dispatch,
  account,
  library,
  loggedIn,
}: Props): React.ReactElement => {
  const theme = useTheme();
  const [data, setData] = useState<IStakeData>(initialData);
  const debouncedData = useDebounce<IStakeData>(data, 300);

  const [stakingAmountInput, setStakingAmountInput] = useState('');
  const [multiplier, setMultiplier] = useState<string>('1.0x');
  const debStakingAmountInput = useDebounce<string>(stakingAmountInput, 300);

  const [rewards, setRewards] = useState<string>('');
  const [tier, setTier] = useState<string>('');
  const [unlockDate, setUnlockDate] = useState<Date>(getTimeFromNow(WEEK_IN_SECONDS * 2));

  const [stakingStatus, setStakingStatus] = useState(Status.INITIAL);
  const [approveStatus, setApproveStatus] = useState(Status.INITIAL);

  const pending = useMemo(
    () => !stakingContract || !tokenContract || stakingState.stateStatus === Status.PENDING,
    [stakingContract, tokenContract, stakingState.stateStatus],
  );

  const allowed = useMemo(() => {
    return tokenState.allowance.gte(data.amount.value);
  }, [tokenState.allowance, data.amount]);

  const stakedBefore = useMemo(() => {
    return stakingState.stakingInfo?.tokenAmount.gt(BigNumber.from('0'));
  }, [stakingState.stakingInfo]);

  useEffect(() => {
    console.log(tokenState);
  }, [tokenState]);

  useEffect(() => {
    if (
      !stakingContract ||
      !debouncedData.amount.value ||
      !debouncedData.weeks.value ||
      debouncedData.weeks.error !== '' ||
      debouncedData.amount.error !== '' ||
      !allowed ||
      !account
    ) {
      return;
    }

    stakingContract
      .getPotentialNftReward(
        debouncedData.amount.value.toString(),
        debouncedData.weeks.value,
        account,
      )
      .then(
        (v) => {
          const rewardsListNumbered = v[0].map((rew) => rew.toNumber());
          const rew = getAllRewards(rewardsListNumbered);
          setRewards(rew);
          const usdt = Number(formatUnits(v[1]).split('.')[0]);
          setTier(getTier(usdt));
          setUnlockDate(new Date(v[2].toNumber() * 1000));
        },
        (error: any) => {
          console.log(error);
        },
      );
  }, [debouncedData, stakingContract, allowed, account]);

  // useEffect(() => {
  //     console.log(debouncedData);
  // }, [debouncedData]);

  useEffect(() => {
    try {
      const bigNum = parseUnits(debStakingAmountInput);
      let error = '';
      if (
        debStakingAmountInput &&
        tokenState.balance.lt(parseUnits(debStakingAmountInput, 'ether'))
      ) {
        error = `You got only: ${formatPrice(tokenState.balance)}`;
      } else if (debStakingAmountInput && Number(debStakingAmountInput) < 0) {
        error = `Can't be negative`;
      }

      setData((state) => ({
        ...state,
        amount: {
          value: bigNum,
          error,
        },
      }));
    } catch (e) {
      console.log('cant parse empty');
    }
  }, [debStakingAmountInput, tokenState.balance]);

  const handleApprove = () => {
    if (!tokenContract || !library) {
      return;
    }
    tokenContract
      .approve(connectionConfig.staking3.address, MAX_UINT256)
      .then((txResponse: TransactionResponse) => {
        setApproveStatus(Status.PENDING);
        toast
          .promise(txResponse.wait(), {
            pending: 'Pending',
            error: 'Error',
            success: `You approved $${tokenState.symbol} for Staking Contract`,
          })
          .then(
            () => {
              setApproveStatus(Status.SUCCESS);
              dispatch(updateTokenPartial({ allowance: MAX_UINT256 }));
            },
            (error: { data: { message: string }; code: string; message: string }) => {
              console.log(error);
              toast.error(error.data ? error.data.message : error.message, {
                hideProgressBar: true,
              });
              setApproveStatus(Status.ERROR);
            },
          );
      });
  };

  const handleStakeError = (error: IWeb3Error) => {
    console.log(error);
    toast.error(error.data ? error.data.message : error.message, { hideProgressBar: true });
    setStakingStatus(Status.ERROR);
  };

  const updateStateAfterStake = () => {
    if (tokenContract && account && stakingContract) {
      dispatch(fetchTokenThunk(tokenContract, account));
      dispatch(fetchStakingThunk(stakingContract, account));
    }
  };

  const handleStake = () => {
    if (!stakingContract || !library) {
      return;
    }
    setStakingStatus(Status.PENDING);
    const stakingAmount = data.amount.value;
    stakingContract
      .stake(stakingAmount.toString(), data.weeks.value)
      .then((res: TransactionResponse) => {
        toast
          .promise(txPending(res.hash, library), {
            pending: 'Pending staking',
            error: 'Error',
            success: `You staked ${stakingAmount} ${tokenState.symbol}`,
          })
          .then(
            () => {
              setStakingStatus(Status.SUCCESS);
            },
            (error: IWeb3Error) => {
              handleStakeError(error);
            },
          )
          .finally(() => {
            updateStateAfterStake();
            setData(initialData);
            setStakingAmountInput('');
            setMultiplier(getMultiplier(2));
          });
      })
      .catch((error: IWeb3Error) => {
        handleStakeError(error);
      });
  };

  const handleApproveFirst = () => {
    toast.info('You need to increase allowance of staking contract before staking');
    handleApprove();
  };

  const handleWeeksChange = (value: any) => {
    const weeksAmount = Number(value.target.value);
    const weeks: IValueError<number> = { value: weeksAmount, error: '' };
    setMultiplier(getMultiplier(weeksAmount));
    setData((state) => ({
      ...state,
      weeks,
    }));
  };

  return (
    <Grid container spacing={2} sx={{ py: 10 }}>
      <Grid item xs={12}>
        <StyledStakeCard>
          <CardContent>
            <Typography
              variant='h5'
              component='h4'
              sx={{
                fontWeight: 800,
                textTransform: 'uppercase',
              }}
            >
              Enter REBL amount and period to stake
            </Typography>
            <Typography component='div' variant='h6'>
              Your balance:
              {loggedIn ? (
                <>
                  <Balance
                    amount={tokenState.balance}
                    symbol={tokenState.symbol}
                    sx={{ display: 'inline', color: '#00e9eb', pl: 1 }}
                  />
                  <MaxButton onClick={() => setStakingAmountInput(formatEther(tokenState.balance))}>
                    Max
                  </MaxButton>
                </>
              ) : (
                <Typography sx={{ pl: 1, fontWeight: 600, color: '#00e9eb' }}>
                  connect to see your balance
                </Typography>
              )}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <TextField
                id='staking_amount'
                size='medium'
                label={`Staking amount (${tokenState.symbol})`}
                variant='outlined'
                type='number'
                disabled={pending}
                sx={{
                  width: '30%',
                  minWidth: '200px',
                  minHeight: 79,
                  mt: 2,
                  mr: 2,
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
                error={data.amount.error !== ''}
                helperText={data.amount.error}
                value={stakingAmountInput}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setStakingAmountInput(event.target.value)
                }
              />
              <FormControl
                sx={{
                  width: '20%',
                  minWidth: '100px',
                  mt: 2,
                  mr: 2,
                  minHeight: 79,
                }}
              >
                <InputLabel id='duration' htmlFor='duration-select'>
                  Duration
                </InputLabel>
                <Select
                  labelId='duration-select-label'
                  id='duration-select'
                  value={data.weeks.value}
                  label='Duration'
                  error={!!data.weeks.error}
                  // helperText={data.weeks.error}
                  disabled={pending}
                  onChange={(value) => handleWeeksChange(value)}
                >
                  {[2, 4, 6, 8, 10, 12].map((duration) => (
                    <MenuItem value={duration} key={duration}>
                      {duration} weeks
                    </MenuItem>
                  ))}
                </Select>
                {data.weeks.error && <FormHelperText>{data.weeks.error}</FormHelperText>}
              </FormControl>

              <Box
                id='staking_multiplier'
                sx={{
                  border: '1px dashed #FFA50055',
                  width: '10%',
                  minWidth: '100px',
                  mt: 2,
                  mr: 2,
                  padding: '15px 14px',
                  color: '#00e9eb',
                  borderRadius: 1.5,
                  position: 'relative',
                  '&::after': {
                    content: '"Multiplier"',
                    position: 'absolute',
                    backgroundColor: '#1d2833',
                    top: -10,
                    left: 10,
                    fontSize: 12,
                    px: 0.5,
                  },
                }}
              >
                {multiplier}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  mt: 0.5,
                }}
              >
                {/* <Typography component='div' sx={{}}>
                  Selected tier {stakedBefore && 'with previous stake'}:
                  <span style={{ fontWeight: 'bold', color: '#00e9eb', paddingLeft: 8 }}>
                    {tier || (
                      <span style={{ fontWeight: 'bold', color: 'red' }}>
                        {allowed ? (debStakingAmountInput ? 'not enough' : '-') : 'approve'}
                      </span>
                    )}
                  </span>
                </Typography> */}
                <Typography component='div' sx={{}}>
                  NFT rewards {stakedBefore && 'with previous stake'}:
                  <span style={{ fontWeight: 'bold', color: '#00e9eb', paddingLeft: 8 }}>
                    {rewards || (
                      <span style={{ fontWeight: 'bold', color: 'red' }}>
                        {allowed ? (debStakingAmountInput ? 'not enough' : '-') : 'approve'}
                      </span>
                    )}
                  </span>
                </Typography>
                <Typography component='div'>
                  Lock until:{' '}
                  <b>{debStakingAmountInput ? unlockDate.toLocaleString('en-us') : '-'}</b>
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              pb: 3,
            }}
          >
            {loggedIn ? (
              allowed ? (
                <>
                  <PrimaryButton
                    isLoading={stakingStatus === Status.PENDING}
                    onClick={handleStake}
                    // variant='contained'
                    disabled={
                      !!data.amount.error ||
                      !!data.weeks.error ||
                      pending ||
                      stakingStatus === Status.PENDING
                    }
                    // size='large'
                  >
                    stake
                  </PrimaryButton>
                </>
              ) : (
                <>
                  <PrimaryButton
                    isLoading={approveStatus === Status.PENDING}
                    onClick={handleApprove}
                    disabled={pending || stakingStatus === Status.PENDING}
                    // variant='contained'
                    // sx={{
                    //   fontWeight: 'bold',
                    //   color: 'white',
                    // }}
                  >
                    approve
                  </PrimaryButton>
                  <Tooltip title={`Approve before staking ${tokenState.symbol}`} placement='top'>
                    <PrimaryButton
                      onClick={handleApproveFirst}
                      disabled={Number(data.amount.value) === 0 || pending}
                      // sx={{
                      //   fontWeight: 'bold',
                      //   color: '#00e9eb',
                      // }}
                    >
                      stake
                    </PrimaryButton>
                  </Tooltip>
                </>
              )
            ) : (
              <WalletModal />
            )}
          </CardActions>
        </StyledStakeCard>
      </Grid>
    </Grid>
  );
};
