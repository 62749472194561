import React from 'react';
import { Box, Typography } from '@mui/material';
import { tierInfos } from 'config/constants/nft';
import { TierCard } from './TierCard';

export const BlockTiers = (): React.ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        component='div'
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          component='h1'
          variant='h3'
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            mb: '5px',
          }}
        >
          NFT Reward Tiers
        </Typography>
        <Typography
          component='h2'
          variant='h5'
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          LEARN ABOUT NFT REWARD TIERS
        </Typography>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mt: 5,
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '3rem',
        }}
      >
        {tierInfos.map((info, i) => (
          <TierCard
            key={`tiers-card-${info.title}`}
            title={info.title}
            rarity={info.rarity}
            price={info.usdPrice}
            votingAmount={info.votingPower}
            order={i}
          />
        ))}
      </Box>
      {/* <Box
        sx={{
          pt: 8,
        }}
      >
        <Typography
          component='img'
          src='/images/space-dog.png'
          sx={{
            pl: 5,
            width: '100%',
            height: 'auto',
            maxHeight: 400,
          }}
        />
      </Box> */}
    </Box>
  );
};
