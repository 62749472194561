import { connectionConfig } from 'config/constants';
import { useUsdPrice } from 'hooks/useUsdPrice';
import styled from 'styled-components';
import { formatBigNumber } from 'utils/formatStringNumber';

const TokenUsdPriceWrapper = styled.div`
  color: ${({ theme }) => theme.primary1};
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  box-shadow: inset 0px 0px 30px ${({ theme }) => theme.shadow1};
  text-shadow: 0 0 20px ${({ theme }) => theme.shadow1};
`;
export function TokenUsdPrice() {
  const usdPrice = useUsdPrice(connectionConfig.token.address);
  // const usdPrice = BigNumber.from(0);

  if (!usdPrice) return null;

  const formattedTokenPrice = formatBigNumber(usdPrice, {
    fractionalFlooring: 8,
    separator: '',
  });

  return <TokenUsdPriceWrapper>REBL price: ${formattedTokenPrice}</TokenUsdPriceWrapper>;
}
