import React, { useMemo } from 'react';
import {
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro';

export type Color = string;
export interface Colors {
  darkMode: boolean;

  // base
  white: Color;
  black: Color;

  // text
  text1: Color;
  text2: Color;
  text3: Color;
  text4: Color;
  text5: Color;

  // backgrounds / greys
  bg0: Color;
  bg1: Color;
  bg2: Color;
  bg3: Color;
  bg4: Color;
  bg5: Color;
  bg6: Color;

  modalBG: Color;
  advancedBG: Color;

  // blues
  primary1: Color;
  primary1Reverse: Color;
  primary2: Color;
  primary3: Color;
  primary4: Color;
  primary5: Color;

  primaryText1: Color;

  // pinks
  secondary1: Color;
  secondary2: Color;
  secondary3: Color;

  // other
  red1: Color;
  red2: Color;
  red3: Color;
  green1: Color;
  yellow1: Color;
  yellow2: Color;
  yellow3: Color;
  blue1: Color;
  blue2: Color;

  blue4: Color;

  error: Color;
  success: Color;
  warning: Color;

  gradient1: Color;
  gradientAlt1: Color;

  gradient2: Color;
  gradientAlt2: Color;

  gradient3: Color;
  gradientAlt3: Color;
}

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecatedZero = 0,
  deprecatedContent = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(
  MEDIA_WIDTHS,
).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const white = '#FFFFFF';
const black = '#000000';

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    // // text
    // text1: darkMode ? '#FFFFFF' : '#000000',
    // text2: darkMode ? '#C3C5CB' : '#565A69',
    // text3: darkMode ? '#8F96AC' : '#6E727D',
    // text4: darkMode ? '#B2B9D2' : '#C3C5CB',
    // text5: darkMode ? '#2C2F36' : '#EDEEF2',

    // // backgrounds / greys
    // bg0: darkMode ? '#191B1F' : '#FFF',
    // bg1: darkMode ? '#212429' : '#F7F8FA',
    // bg2: darkMode ? '#2C2F36' : '#EDEEF2',
    // bg3: darkMode ? '#40444F' : '#CED0D9',
    // bg4: darkMode ? '#565A69' : '#888D9B',
    // bg5: darkMode ? '#6C7284' : '#5f727f',
    // bg6: darkMode ? '#1A2028' : '#000',

    // text
    text1: darkMode ? '#FFFFFF' : '#FFF',
    text2: darkMode ? '#C3C5CB' : '#EDEEF2',
    text3: darkMode ? '#8F96AC' : '#888D9B',
    text4: darkMode ? '#B2B9D2' : '#5f727f',
    text5: darkMode ? '#2C2F36' : '#000',

    // backgrounds / greys
    bg0: darkMode ? '#191B1F' : '#0c0c0c',
    bg1: darkMode ? '#212429' : '#1d2833',
    bg2: darkMode ? '#2C2F36' : '#1d2833',
    bg3: darkMode ? '#40444F' : '#2b3b4a',
    bg4: darkMode ? '#565A69' : '#888D9B',
    bg5: darkMode ? '#6C7284' : '#c7cfd3',
    bg6: darkMode ? '#1A2028' : '#0f0f0f',

    // specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    // primary colors
    primary1: darkMode ? '#2172E5' : '#00e9eb',
    primary1Reverse: !darkMode ? '#2172E5' : '#E8006F',
    primary2: darkMode ? '#3680E7' : '#00bcd4',
    primary3: darkMode ? '#4D8FEA' : '#70c6d3',
    primary4: darkMode ? '#376bad70' : '#bae1e6',
    primary5: darkMode ? '#153d6f70' : '#384554',

    // color text
    primaryText1: darkMode ? '#5090ea' : '#083b49',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#E8006F',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: darkMode ? '#FF4343' : '#DA2D2B',
    red2: darkMode ? '#F82D3A' : '#DF1F38',
    red3: '#D60000',
    green1: darkMode ? '#27AE60' : '#007D35',
    yellow1: '#E3A507',
    yellow2: '#FF8F00',
    yellow3: '#F3B71E',
    blue1: darkMode ? '#2172E5' : '#0068FC',
    blue2: darkMode ? '#5199FF' : '#0068FC',
    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#33d72d' : '#007D35',
    warning: '#FF8F00',

    // dont wanna forget these blue yet
    blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',

    gradient1: 'linear-gradient(265deg, #135ba4 -13.13%, rgba(19, 91, 164, 0) 109.55%)',
    gradientAlt1: 'linear-gradient(85deg, #135ba4 -8.22%, rgba(19, 91, 164, 0.31) 106.39%)',

    gradient2: 'linear-gradient(85deg, rgba(29, 40, 51, 0) -20.76%, #376D4D 125.28%)',
    gradientAlt2: 'linear-gradient(85deg, #376D4D -20.76%, rgba(29, 40, 51, 0) 125.28%)',

    // gradient3: 'linear-gradient(250deg, #00e9eb -35%, #1d2833 100%)',
    // gradientAlt3: 'linear-gradient(250deg, #1d2833 0, #00e9eb 160%)',
    gradient3: 'linear-gradient(250deg, #05abae 0, #1d2833 125%)',
    gradientAlt3: 'linear-gradient(250deg, #1d2833 -20%, #05abae 125%)',
  };
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    // shadows
    shadow1: darkMode ? '#000' : '#00e7eb2f',
    shadow2: '#ffffff38',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const themeObject = useMemo(() => theme(false), []);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
  );
}
