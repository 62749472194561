import { Dispatch } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { IRank, IStakingContract, IStakingInfoResp } from './interfaces/contract.interface';
import { Status } from '../../interfaces/statuses';
import {
  fetchStaking,
  fetchStakingError,
  fetchStakingSuccess,
  updateTop10StakingSuccess,
} from './actions';
import { IStakingData } from './interfaces/data.interface';

export const fetchStakingThunk =
  (stakingContract: IStakingContract, account: string) => async (dispatch: Dispatch) => {
    dispatch(fetchStaking());
    try {
      Promise.all([
        new Promise<{ stakingInfoResp: IStakingInfoResp; rewards: BigNumber[] }>(
          (resolve, reject) => {
            stakingContract.usersStaking(account).then(
              (stakingInfoResp: IStakingInfoResp) => {
                stakingContract
                  .getActualNftReward(stakingInfoResp.usdtAmountForReward.toString())
                  .then(
                    (rewards: BigNumber[]) => {
                      resolve({ stakingInfoResp, rewards });
                    },
                    (error: any) => {
                      reject(error);
                    },
                  );
              },
              (error: any) => {
                reject(error);
              },
            );
          },
        ),
        stakingContract.getAllInvestors(),
      ])
        .then((value) => {
          const stakingData: IStakingData = {
            stakingInfo: {
              rewardUnblockTimestamp: value[0].stakingInfoResp.rewardUnblockTimestamp.toNumber(),
              usdtAmountForReward: value[0].stakingInfoResp.usdtAmountForReward.toString(),
              tokenAmount: value[0].stakingInfoResp.tokenAmount.toString(),
              periodInWeeks: value[0].stakingInfoResp.periodInWeeks.toNumber(),
            },
            // rewards: value[0].rewards.map((reward: BigNumber) => reward.toNumber())
            rewards: value[0].rewards.map((v: BigNumber) => v.toNumber()),
            top10: value[1]
              .map((v) => ({ account: v.account, multipliedValue: v.multipliedValue.toString() }))
              .sort((a: IRank, b: IRank) =>
                BigNumber.from(a.multipliedValue).gt(b.multipliedValue) ? -1 : 1,
              )
              .slice(0, 10)
              .map((v) => ({
                ...v,
                multipliedValue: `${formatUnits(v.multipliedValue, 18).split('.')[0]} $`,
              })),
            top10Status: Status.PENDING,
          };

          try {
            Promise.all(stakingData.top10.map((top) => stakingContract.usersStaking(top.account)))
              .then((v) => {
                const top10 = stakingData.top10.map((top, index) => ({
                  ...top,
                  tokenAmount: formatUnits(v[index].tokenAmount, 18).split('.')[0],
                  periodInWeeks: v[index].periodInWeeks.toNumber(),
                }));
                dispatch(updateTop10StakingSuccess({ top10 }));
              })
              .catch((error: Error) => {
                console.log(error);
              });
          } catch (error) {
            console.error(error);
            // dispatch(fetchSaleError({error: error as Error}));
          }

          dispatch(fetchStakingSuccess({ stakingData }));
        })
        .catch((error: Error) => {
          dispatch(fetchStakingError({ error }));
        });
    } catch (error) {
      console.error(error);
      dispatch(fetchStakingError({ error: error as Error }));
    }
  };
