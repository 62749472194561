import { Status } from './statuses';

export interface ICommonState {
  stateStatus: Status;
  error: Error | null;
}

export const initialCommonState: ICommonState = {
  stateStatus: Status.INITIAL,
  error: null,
};
