import React from 'react';
import { Box } from '@mui/material';
import { ScoreNFT } from '../../UI/Dialogs/ScoreNFT';
import { useNFTState } from '../../../state/contract-nft/hooks';
import { getRewardName, tiers } from '../../../utils/calcHelper';

export const MyNFT = () => {
  const nftState = useNFTState();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mt: 5,
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        {nftState.amountByLevel
          // .filter((amount) => amount > 0)
          .map(
            (amount, index) =>
              amount > 0 && (
                <ScoreNFT
                  key={tiers[index]}
                  title={tiers[index]}
                  description={getRewardName(index)}
                  img={`/media/${index}.webp`}
                  score={amount}
                />
              ),
          )}
      </Box>
    </Box>
  );
};
