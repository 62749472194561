import MigrateModal from 'components/MigrateModal';
import { TokenUsdPrice } from 'components/TokenUsdPrice';
import WalletModal from 'components/WalletModal';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import styled from 'styled-components';

const WalletModalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  gap: 1rem;

  box-sizing: border-box;
  margin: 0 0 2.5rem;
`;

export function MainUI() {
  const { account } = useActiveWeb3React();

  return (
    <WalletModalContainer>
      {account && <MigrateModal />}
      <WalletModal />
      <TokenUsdPrice />
    </WalletModalContainer>
  );
}
