import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { getUsdPrice } from 'utils/getUsdPrice';
import useActiveWeb3React from './useActiveWeb3React';
import { useRouter2Contract } from './useContract';

export function useUsdPrice(address: string) {
  const { chainId } = useActiveWeb3React();
  const [usdPrice, setUsdPrice] = useState<BigNumber | null>(null);
  const router2Contract = useRouter2Contract();

  useEffect(() => {
    if (!router2Contract || !chainId || !address) return;

    try {
      (async function () {
        const fetchedUsdPrice = await getUsdPrice(address, router2Contract);
        setUsdPrice(fetchedUsdPrice);
      })();
    } catch (e) {
      console.error(`Error while fetching usd price for ${address}\n`, e);
    }
  }, [router2Contract, chainId, address]);

  return usdPrice;
}
