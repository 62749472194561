import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';

export const MUIThemeProvider: React.FC = ({ children }) => {
  const mode = 'dark';
  const black = '#111111';
  const white = '#fff';
  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: 'Inter, sansTextField-serif',
        },
        shape: {
          borderRadius: 12,
        },
        palette: {
          mode,
          common: {
            black,
            white,
          },
          primary: {
            light: '#00e9eb',
            main: '#00e9eb',
            dark: '#083b49',
            contrastText: '#083b49',
          },
          secondary: {
            light: '#F2F4F9',
            main: '#E0E1E9',
            dark: '#93939B',
            contrastText: '#000',
          },
        },
        components: {
          MuiTypography: {
            defaultProps: {
              variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h5',
                h6: 'h6',
                subtitle1: 'h4',
                subtitle2: 'h4',
                body1: 'span',
                body2: 'span',
              },
            },
          },
          MuiContainer: {
            styleOverrides: {
              root: {
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
          MuiToolbar: {
            styleOverrides: {
              root: {
                background: black,
              },
              gutters: {
                '& .MuiContainer-root': {
                  maxWidth: 1400,
                },
                minHeight: '0px !important',
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                fontWeight: 800,
              },
            },
          },
        },
      }),
    [mode, white, black],
  );

  const responsiveTheme = responsiveFontSizes(theme);

  return <ThemeProvider theme={responsiveTheme}>{children}</ThemeProvider>;
};
