import { H2 } from 'components/Common/Headers';
import styled from 'styled-components';
import { useTreasuryInfo } from '../hooks/useTreasuryInfo';
import { TotalTreasury } from './TotalTreasury';
import { TreasuryInvesments } from './TreasuryInvesments';
import { TreasuryReserves } from './TreasuryReserves';

const TreasurySectionWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  column-gap: 2rem;

  /* width: fit-content; */
  width: 100%;
  margin: 0 auto;

  /* grid-template-areas: 'heading heading' 'reserves total' 'investment total'; */
  grid-template-areas:
    'heading heading'
    'total total'
    'reserves investment';
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-areas: 
      'heading heading' 
      'total total' 
      'reserves reserves' 
      'investment investment';
  `}
`;

const TreasuryHeading = styled(H2)`
  grid-area: heading;
`;

export function TreasurySection() {
  const treasuryInfo = useTreasuryInfo();

  return (
    <TreasurySectionWrapper>
      <TreasuryHeading>Treasury</TreasuryHeading>
      <TotalTreasury totalValue={treasuryInfo.totalUsd} />
      <TreasuryReserves reserves={treasuryInfo.reservesTable} />
      <TreasuryInvesments investmentInfos={treasuryInfo.investmentsTable} />
    </TreasurySectionWrapper>
  );
}
