import React, { useEffect, useMemo, useState } from 'react';
import { Box, CardActions, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { Dispatch } from '@reduxjs/toolkit';
import { Web3Provider } from '@ethersproject/providers';
import { PrimaryButton, SecondaryButton } from 'components/Common/Button';
import { ITokenContract } from '../../../interfaces/token-contract.interface';
import { SLOW_INTERVAL } from '../../../config/constants';
import { Status } from '../../../interfaces/statuses';
import WalletModal from '../../WalletModal';
import { Balance } from '../../UI/Balance';
import { IWeb3Error } from '../../../interfaces/web3-error.interface';
import { txPending } from '../../../utils/tx-pending';
import { getAllRewards } from '../../../utils/calcHelper';
import useAuth from '../../../hooks/useAuth';
import { IStakingContract } from '../../../state/contract-staking/interfaces/contract.interface';
import { ITokenStateFormatted } from '../../../state/contract-token/interfaces/data.interface';
import { fetchTokenThunk } from '../../../state/contract-token';
import { fetchStakingThunk } from '../../../state/contract-staking';
import { IStakingStateFormatted } from '../../../state/contract-staking/interfaces/data.interface';
import { StyledStakeCard } from './styles';

interface Props {
  tokenState: ITokenStateFormatted;
  tokenContract: ITokenContract | null;
  stakingState: IStakingStateFormatted;
  stakingContract: IStakingContract | null;
  dispatch: Dispatch<any>;
  account?: string | null;
  library?: Web3Provider;
  loggedIn: boolean;
}

export const CardStakingControl = ({
  tokenState,
  tokenContract,
  stakingState,
  stakingContract,
  dispatch,
  account,
  library,
  loggedIn,
}: Props): React.ReactElement => {
  const { logout } = useAuth();
  const [withReward, setWithReward] = useState<boolean>(
    stakingState.stakingInfo.rewardUnblockTimestamp.toNumber() * 1000 < new Date().getTime(),
  );
  const [withdrawStatus, setWithdrawStatus] = useState(Status.INITIAL);
  const [rewards, setRewards] = useState<string>('none');
  const pending = useMemo(
    () => !stakingContract || !tokenContract || stakingState.stateStatus === Status.PENDING,
    [stakingContract, tokenContract, stakingState.stateStatus],
  );

  useEffect(() => {
    const rewardsListNumbered = stakingState.rewards.map((rew) => rew.toNumber());
    const rew = getAllRewards(rewardsListNumbered);
    // console.log(rewardsListNumbered, v, rew);
    setRewards(rew);
  }, [stakingState.rewards]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const withRew =
        stakingState.stakingInfo.rewardUnblockTimestamp.toNumber() * 1000 < new Date().getTime();
      if (withReward !== withRew) {
        setWithReward(withRew);
      }
    }, SLOW_INTERVAL);
    return () => clearInterval(interval);
  }, [stakingState.stakingInfo.rewardUnblockTimestamp, withReward]);

  const handleWithdrawError = (error: IWeb3Error) => {
    console.log(error);
    toast.error(error.data ? error.data.message : error.message, { hideProgressBar: true });
    setWithdrawStatus(Status.ERROR);
  };

  const updateStateAfterWithdraw = () => {
    if (tokenContract && account && stakingContract) {
      dispatch(fetchTokenThunk(tokenContract, account));
      dispatch(fetchStakingThunk(stakingContract, account));
    }
  };

  const handleWithdraw = (withRewards: boolean) => {
    if (!stakingContract || !library) {
      return;
    }
    setWithdrawStatus(Status.PENDING);
    if (withRewards) {
      stakingContract
        .unstakeWithReward()
        .then((res: TransactionResponse) => {
          toast
            .promise(txPending(res.hash, library), {
              pending: 'Pending withdrawing',
              error: 'Error',
              success: `You withdrawn your tokens`,
            })
            .then(
              () => {
                setWithdrawStatus(Status.SUCCESS);
              },
              (error: IWeb3Error) => {
                handleWithdrawError(error);
              },
            )
            .finally(() => {
              updateStateAfterWithdraw();
            });
        })
        .catch((error: IWeb3Error) => {
          handleWithdrawError(error);
        });
    } else {
      stakingContract
        .unstakeWithoutReward()
        .then((res: TransactionResponse) => {
          toast
            .promise(txPending(res.hash, library), {
              pending: 'Pending staking',
              error: 'Error',
              success: `You withdrawn your tokens and NFTs`,
            })
            .then(
              () => {
                setWithdrawStatus(Status.SUCCESS);
              },
              (error: IWeb3Error) => {
                handleWithdrawError(error);
              },
            )
            .finally(() => {
              updateStateAfterWithdraw();
            });
        })
        .catch((error: IWeb3Error) => {
          handleWithdrawError(error);
        });
    }
  };

  return (
    <Grid container spacing={2} sx={{ pt: 3 }}>
      <Grid item xs={12} paddingBottom={10}>
        <StyledStakeCard>
          {loggedIn ? (
            <>
              <CardContent>
                <Typography component='div' variant='h6'>
                  Staked tokens:
                  <Balance
                    amount={stakingState.stakingInfo.tokenAmount}
                    symbol={tokenState.symbol}
                    sx={{ display: 'inline', color: '#00e9eb', pl: 1 }}
                  />
                </Typography>
                <Typography component='div' variant='h6'>
                  Calculated rewards:
                  <Balance
                    amount={stakingState.stakingInfo.usdtAmountForReward}
                    symbol='$'
                    sx={{ display: 'inline', color: '#00e9eb', pl: 1 }}
                  />
                </Typography>
                <Typography component='div' variant='h6'>
                  Unlock time:
                  <span style={{ paddingLeft: 6 }}>
                    {new Date(
                      stakingState.stakingInfo.rewardUnblockTimestamp.toNumber() * 1000,
                    ).toLocaleString('en-us')}
                  </span>
                </Typography>
                <Typography component='div' variant='h6' sx={{}}>
                  Rewards:
                  <span style={{ color: '#00e9eb', paddingLeft: 8 }}>{rewards}</span>
                </Typography>
              </CardContent>
              <CardActions
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  pb: 3,
                }}
              >
                {!withReward && (
                  <Tooltip
                    title='Only after unlock time you will be able to withdraw with rewards'
                    placement='top'
                  >
                    <PrimaryButton
                      isLoading={withdrawStatus === Status.PENDING}
                      onClick={() => handleWithdraw(false)}
                      // variant='contained'
                      disabled={pending}
                      // size='large'
                    >
                      Withdraw without rewards
                    </PrimaryButton>
                  </Tooltip>
                )}
                <PrimaryButton
                  isLoading={withdrawStatus === Status.PENDING && withReward}
                  onClick={() => handleWithdraw(true)}
                  // variant='contained'
                  disabled={pending || !withReward}
                  // size='large'
                >
                  Withdraw
                </PrimaryButton>
              </CardActions>
            </>
          ) : (
            <Box
              sx={{
                minHeight: 266,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <WalletModal />
            </Box>
          )}
        </StyledStakeCard>
      </Grid>
      {loggedIn && (
        <Grid item xs={12} justifyContent='center' display='flex'>
          <SecondaryButton onClick={() => logout()}>Disconnect</SecondaryButton>
        </Grid>
      )}
    </Grid>
  );
};
