import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { formatBigNumber } from 'utils/formatStringNumber';
import { useStakingContractV2 } from './useContractOld';

export function useTokenStaking() {
  const [tokenStaking, setTokenStaking] = useState<string | null>(null);

  const stakingContract = useStakingContractV2();

  useEffect(() => {
    if (!stakingContract) return;

    (async function () {
      const investors = await stakingContract.getAllInvestors();
      const stakingRespones = await Promise.all(
        investors.map((investor) => stakingContract.usersStaking(investor.account)),
      );

      const stakedTokenAmountBN = stakingRespones.reduce(
        (prev, cur) => prev.add(cur.tokenAmount),
        BigNumber.from(0),
      );

      setTokenStaking(formatBigNumber(stakedTokenAmountBN, { fractionalFlooring: 0 }));
    })();
  }, [stakingContract]);

  return tokenStaking;
}
