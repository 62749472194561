import { Erc20, Nft2 } from 'config/constants/abis/types';
import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers';
import { Contract } from 'ethers';
import { AddressZero } from '@ethersproject/constants';
import { isAddress } from 'ethers/lib/utils';
import { connectionConfig } from 'config/constants';
import { Router2 } from 'config/constants/abis/types/Router2';
import ERC20_ABI from '../config/constants/abis/erc20.json';

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function getERC20Contract(library: Web3Provider, address: string, account?: string): Erc20 {
  return getContract(address, ERC20_ABI, library, account) as Erc20;
}

export function getWethContract(library: Web3Provider, account?: string): Erc20 {
  return getERC20Contract(library, connectionConfig.addresses.weth, account) as Erc20;
}

export function getRouter2Contract(library: Web3Provider, account?: string): Router2 {
  return getContract(
    connectionConfig.router2.address,
    connectionConfig.router2.abi,
    library,
    account,
  ) as Router2;
}

export function getNFTContractV2(library: Web3Provider, account?: string): Nft2 {
  return getContract(
    connectionConfig.nft2.address,
    connectionConfig.nft2.abi,
    library,
    account,
  ) as Nft2;
}

export function getChainRouter02Contract(
  library: Web3Provider,
  router02Address: string,
  account?: string,
) {
  return getContract(router02Address, connectionConfig.router2.abi, library, account) as Router2;
}
