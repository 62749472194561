import { parseInt } from 'lodash';
import sample from 'lodash/sample';
import {
  ChainId,
  ENetworkType,
  IConnectionConfig,
  INetworkConfig,
} from '../../interfaces/connection-config.interface';
import { networksConfig } from './networks';
import { getNetworkType } from '../../utils/networks';
import { abi as nftAbiTest } from './nft_test.json';
import { abi as nftAbiTestV2 } from './nft_test_v2.json';
import { abi as nftAbiProd } from './nft_prod.json';
import { abi as nftAbiProdV2 } from './nft_prod_v2.json';
import { abi as tokenAbiTest } from './token_test.json';
import { abi as tokenAbiProd } from './token_prod.json';
import { abi as stakingAbiTestV1 } from './staking_test.json';
import { abi as stakingAbiTestV2 } from './staking_test_v2.json';
import { abi as stakingAbiProd } from './staking_prod.json';
import { abi as stakingAbiProdV2 } from './staking_prod_v2.json';
import { abi as multicallAbiTest } from './multicall_test.json';
import { abi as multicallAbiProd } from './multicall_prod.json';
import router2AbiProd from './abis/router2.json';

export const MAX_UINT256 =
  '115792089237316195423570985008687907853269984665640564039457584007913129639935';

export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
];

export const FAST_INTERVAL = 1500;
export const SLOW_INTERVAL = 3000;

export const connectorLocalStorageKey = 'connectorId';
export const walletLocalStorageKey = 'wallet';
export const isDarkLocalStorageKey = 'isDark';

const TOKEN_CONTRACT_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0xbB8b7E9A870FbC22ce4b543fc3A43445Fbf9097f',
  [ENetworkType.test]: '0x230eF08f78B81C6C6B38e79d5D7eFe54f8A0e473',
};

const WETH_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  [ENetworkType.test]: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
};

const USD_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  [ENetworkType.test]: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
};

const TOKEN_WETH_PAIR_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0x54d79E2c9A7fe6d40623Ee0Cc91BF970Ce6fC150',
  [ENetworkType.test]: '0x4e7C186bfe17C0a8A60127919B06a0060144C149',
};

const NFT_CONTRACT_ADDRESS_V1: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0xEB93dEeD34E1D062eA7CEdC4C95C1dedD6eF4B1e',
  [ENetworkType.test]: '0x6FbDb5E87Eb163841B5c4b9C5376945B76F9833A',
};

const NFT_CONTRACT_ADDRESS_V2: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0xFfEDd14bE5768C2852328D0a51c613db2ba2322d',
  [ENetworkType.test]: '0xCb192956680bFeA1DE5542A0df375Ad8de725339',
};

const STAKING_CONTRACT_ADDRESS_V2: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0xC1b102872B2A85f09CfdC6723CF7506598112e82',
  [ENetworkType.test]: '0x230eF08f78B81C6C6B38e79d5D7eFe54f8A0e473',
};

const STAKING_CONTRACT_ADDRESS_V3: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0x7aCC6Cc7bAa3AA8C3dAf27D21aA0cCa090F4BA3C',
  [ENetworkType.test]: '0x53f780867e144e74f68Eb9e22Dc3b4d76C7984a9',
};

const MULTICALL_CONTRACT_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0x09E8Df8Ac4aAF70BeBF1F5e1e14274f563c583dD',
  [ENetworkType.test]: '0xc1B88A4b9ACDc9bdA6E243CC76232853e1247711',
};

const ROUTER_V2_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  [ENetworkType.test]: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
};

const TREASURY_ADDRESS: { [key in ENetworkType]: string } = {
  [ENetworkType.prod]: '0x08a02cba40d32cdf3c3f8291d256683a472abb28',
  [ENetworkType.test]: '0x000000000000000000000000000000000000dEaD',
};

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID || '97') as ChainId;
const networkType = getNetworkType(chainId);
export const networkConfig: INetworkConfig = networksConfig[chainId];
export const connectionConfig: IConnectionConfig = {
  ...networkConfig,
  token: {
    address: TOKEN_CONTRACT_ADDRESS[networkType],
    abi: networkType === ENetworkType.prod ? tokenAbiProd : tokenAbiTest,
  },
  nft: {
    address: NFT_CONTRACT_ADDRESS_V1[networkType],
    abi: networkType === ENetworkType.prod ? nftAbiProd : nftAbiTest,
  },
  nft2: {
    address: NFT_CONTRACT_ADDRESS_V2[networkType],
    abi: networkType === ENetworkType.prod ? nftAbiProdV2 : nftAbiTestV2,
  },
  staking2: {
    address: STAKING_CONTRACT_ADDRESS_V2[networkType],
    abi: networkType === ENetworkType.prod ? stakingAbiProd : stakingAbiTestV1,
  },
  staking3: {
    address: STAKING_CONTRACT_ADDRESS_V3[networkType],
    abi: networkType === ENetworkType.prod ? stakingAbiProdV2 : stakingAbiTestV2,
  },
  multicall: {
    address: MULTICALL_CONTRACT_ADDRESS[networkType],
    abi: networkType === ENetworkType.prod ? multicallAbiProd : multicallAbiTest,
  },
  router2: {
    address: ROUTER_V2_ADDRESS[networkType],
    abi: router2AbiProd,
  },
  addresses: {
    weth: WETH_ADDRESS[networkType],
    usd: USD_ADDRESS[networkType],
    tokenWethPair: TOKEN_WETH_PAIR_ADDRESS[networkType],
    treasury: TREASURY_ADDRESS[networkType],
  },
  chainId,
};

export const getRandomRpcUrl = (): string => {
  return sample(connectionConfig.rpcUrls) || networksConfig[chainId].rpcUrls[0];
};

export const WEEK_IN_SECONDS = 604800;

export const STAKING_CONTRACT_V2_ADDRESS = STAKING_CONTRACT_ADDRESS_V3[networkType];

export const treasuryTokens: string[] = [
  TOKEN_CONTRACT_ADDRESS[networkType],
  WETH_ADDRESS[networkType],
  USD_ADDRESS[networkType],
];
