import styled from 'styled-components';

export const StyledStakeCard = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 0.5rem;

  padding: 1rem 2rem;
`;

export const Top10InfoBlock = styled.div`
  max-width: 100%;
  margin: 5rem auto 0;
`;

export const Top10TableContainer = styled.div`
  max-width: 100%;
`;

export const Top10Table = styled.table`
  overflow-x: scroll;

  display: block;
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;

  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};

  border-radius: 0.5rem;

  thead tr {
    background-color: ${({ theme }) => theme.bg3};
    box-shadow: inset 0px 0px 30px ${({ theme }) => theme.shadow1};
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 1rem;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 1rem;
  }

  /* hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const Top10TR = styled.tr`
  background-color: ${({ theme }) => theme.bg1};

  &:last-child td {
    padding-bottom: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 3px solid ${({ theme }) => theme.bg2};
  }
`;
export const Top10TH = styled.th`
  background-color: ${({ theme }) => theme.bg3};
  padding: 0.5rem 1rem;
  white-space: nowrap;

  text-align: start;

  &:nth-child(2) {
    width: 99%;
  }

  &:first-child {
    border-top-left-radius: 0.7rem;
  }

  &:last-child {
    border-top-right-radius: 0.7rem;
  }
`;
export const Top10TD = styled.td`
  background-color: transparent;
  padding: 0.5rem 1rem;
  white-space: nowrap;

  &:nth-child(2) {
    width: 99%;
  }

  &:first-child {
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 2rem;
  }
`;
