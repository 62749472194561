import { LoadingBox } from 'components/Common/LoadingBox';
import { MouseoverTooltip } from 'components/Common/Tooltip';
import { daoStatuses } from 'config/constants/nft';
import { useDaoInfo } from 'state/contract-nft/hooks';
import styled, { DefaultTheme } from 'styled-components';
import { ReactComponent as QuestionMarkSvg } from '../../../assets/icons/question mark.svg';

const DaoVotingInfoWrapper = styled.div`
  /* 
  display: flex;
  flex-direction: column; 
  gap: 1rem;
  */
  display: grid;
  grid-template-areas: 'status-title power-title total-title' 'status-description power-description total-description' 'link link link';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  row-gap: 0.5rem;
  column-gap: 5%;

  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 0.5rem;

  @media (max-width: 1000px) {
    grid-template-areas: 'status-title' 'status-description' 'power-title' 'power-description' 'total-title' ' total-description' 'link';
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
`;
const DaoVotingMainInfo = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`;

const DaoVotingInfoTitle = styled.h2<{ area: string }>`
  grid-area: ${({ area }) => `${area}-title`};

  display: inline-flex;
  align-items: flex-end;
  gap: 0.25rem;

  margin: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.text3};
`;
const DaoVotingInfoDescription = styled.p<{ rank: number; area: string; disablePadding?: boolean }>`
  grid-area: ${({ area }) => `${area}-description`};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  ${({ disablePadding }) => !disablePadding && 'padding: 1rem 2rem;'}

  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;

  border-radius: 10px;

  &:nth-child(4n + 2) {
    background: ${({ theme, rank }) => theme[`gradient${rank}` as keyof DefaultTheme]};
  }
  &:nth-child(4n) {
    background: ${({ theme, rank }) => theme[`gradientAlt${rank}` as keyof DefaultTheme]};
  }

  @media (max-width: 1000px) {
    margin-bottom: 1rem;
  }
`;

const VotingPowerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const LinkArea = styled.div`
  grid-area: link;
  margin-top: 0.5rem;
`;

const QuestionIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const FAQTooltip = styled(MouseoverTooltip)`
  display: flex;
  align-items: center;
`;

const FAQAnchor = styled.a`
  width: fit-content;

  font-size: 14px;
  color: ${({ theme }) => theme.text3};

  transition: color 0.5s;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    color: ${({ theme }) => theme.primary4};
    cursor: pointer;
  }
`;

function DaoFAQTooltip() {
  return (
    <MouseoverTooltip
      text='DAO FAQ & how to upgrade my DAO status and become a protector'
      placement='top'
    >
      <QuestionIconContainer>
        <QuestionMarkSvg width='1rem' height='1rem' />
      </QuestionIconContainer>
    </MouseoverTooltip>
  );
}

type DaoVotingInfoProps = {
  isLoading: boolean;
};

export function DaoVotingInfo({ isLoading }: DaoVotingInfoProps) {
  const daoInfo = useDaoInfo();

  if (isLoading) return <LoadingDaoVotingInfo />;

  const daoStatusRank = daoStatuses.findIndex((p) => p === daoInfo.daoStatus) + 1;
  return (
    <DaoVotingInfoWrapper>
      <DaoVotingInfoTitle area='status'>My DAO status:</DaoVotingInfoTitle>
      <DaoVotingInfoDescription area='status' rank={daoStatusRank}>
        {daoInfo.daoStatus}
      </DaoVotingInfoDescription>
      <DaoVotingInfoTitle area='power'>
        <VotingPowerContainer>
          My voting power:
          <FAQAnchor
            href='https://wp.rebellionprotocol.com/rebellion-vc-fund/the-investment-dao'
            target='_blank'
          >
            <DaoFAQTooltip />
          </FAQAnchor>
        </VotingPowerContainer>
      </DaoVotingInfoTitle>
      <DaoVotingInfoDescription area='power' rank={daoStatusRank}>
        {daoInfo.daoPower} DAO VOTES
      </DaoVotingInfoDescription>
      <DaoVotingInfoTitle area='total'>Total voting power in circulation:</DaoVotingInfoTitle>
      <DaoVotingInfoDescription area='total' rank={daoStatusRank}>
        {daoInfo.totalDaoPower} DAO VOTES
      </DaoVotingInfoDescription>

      <LinkArea>
        <FAQAnchor
          href='https://wp.rebellionprotocol.com/rebellion-vc-fund/the-investment-dao'
          target='_blank'
        >
          {daoInfo.daoStatus === 'Protector' ? 'DAO Investment FAQ' : 'How to become a protector'}
          <DaoFAQTooltip />
        </FAQAnchor>
      </LinkArea>
    </DaoVotingInfoWrapper>
  );
}
// DAO FAQ & how to upgrade my DAO status and become a protector

function LoadingDaoVotingInfo() {
  return (
    <DaoVotingInfoWrapper>
      <DaoVotingInfoTitle area='status'>
        <LoadingBox width='6rem' height='0.75rem' />
      </DaoVotingInfoTitle>
      <DaoVotingInfoDescription as='div' area='status' rank={0} disablePadding>
        <LoadingBox width='100%' height='4rem' borderRadius='10px' />
      </DaoVotingInfoDescription>
      <DaoVotingInfoTitle area='power'>
        <VotingPowerContainer>
          <LoadingBox width='8rem' height='0.75rem' />
        </VotingPowerContainer>
      </DaoVotingInfoTitle>
      <DaoVotingInfoDescription as='div' area='power' rank={0} disablePadding>
        <LoadingBox width='100%' height='4rem' borderRadius='10px' />
      </DaoVotingInfoDescription>
      <DaoVotingInfoTitle area='total'>
        <LoadingBox width='12rem' height='0.75rem' />
      </DaoVotingInfoTitle>
      <DaoVotingInfoDescription as='div' area='total' rank={0} disablePadding>
        <LoadingBox width='100%' height='4rem' borderRadius='10px' />
      </DaoVotingInfoDescription>

      <LinkArea>
        <LoadingBox width='12rem' height='0.75rem' />
      </LinkArea>
    </DaoVotingInfoWrapper>
  );
}
