import { Download } from '@mui/icons-material';
import { CheckSquare } from 'react-feather';
import styled from 'styled-components';

type TierCardProps = {
  title: string;
  rarity: string;
  price: number;
  votingAmount: number;
  order: number;
};

const TierCardWrapper = styled.div`
  width: 40%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 2rem 3rem;

  gap: 2rem;

  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.text1};

  text-transform: uppercase;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding-bottom: 1.5rem;
 `}
`;

const NFTImg = styled.img`
  width: 100%;
  box-shadow: 0px 0px 30px ${({ theme }) => theme.shadow1};
`;

const TierCardInfoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: 'voting rarity' 'title title' 'description description';
  grid-template-columns: 1fr 10rem;
  row-gap: 1rem;
  column-gap: 1rem;

  vertical-align: middle;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-areas:  'rarity rarity' 'voting voting' 'title title' 'description description';
  `}
`;

const TierCardVoting = styled.div`
  grid-area: voting;

  display: flex;
  align-items: center;

  font-size: 1.2rem;

  color: ${({ theme }) => theme.primary1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 1rem;
  `}
`;
const TierCardRarity = styled.div`
  grid-area: rarity;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.25rem;

  font-weight: bold;

  color: ${({ theme }) => theme.primary1};
  border: 2px solid ${({ theme }) => theme.primary1};
  border-radius: 5px;
`;

const TierCardTitle = styled.h3`
  grid-area: title;

  font-size: 2.5rem;

  display: flex;
  align-items: center;

  margin: 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 1.4rem;
  `}
`;

const TierCardDescription = styled.div`
  grid-area: description;

  display: grid;
  grid-template-areas: 'text download';
  grid-template-columns: auto 1fr;
  row-gap: 1rem;

  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    grid-template-areas: 'text text' 'download download';
 `}
`;
const TierCardDescriptionText = styled.p`
  grid-area: text;

  margin: 0;
  opacity: 0.8;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    font-size: 0.85rem;
    `}
`;

const DownloadArea = styled.div`
  margin-left: auto;
  grid-area: download;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;

  color: ${({ theme }) => theme.primary1};

  a {
    height: 2rem;
    color: inherit;
    :hover {
      opacity: 0.7;
    }
    :active {
      opacity: 0.4;
    }

    padding: 0.5rem;
    border: 2px solid ${({ theme }) => theme.primary1};
    border-radius: 50%;
  }

  svg {
    width: 2rem;
    height: 2rem;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: auto;
    
    a {
      height: 1.5rem;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
 `}
`;

const VoteIcon = styled(CheckSquare)`
  margin-right: 0.5rem;
`;

export function TierCard({ title, rarity, price, votingAmount, order }: TierCardProps) {
  const tierNftPath = `/media/${order}`;
  return (
    <TierCardWrapper>
      <NFTImg src={`${tierNftPath}.webp`} alt='NFT' />
      <TierCardInfoGrid>
        <TierCardVoting>
          <VoteIcon size='1.2rem' /> {votingAmount} voting rights
        </TierCardVoting>
        <TierCardRarity>{rarity}</TierCardRarity>
        <TierCardTitle>{title}</TierCardTitle>
        <TierCardDescription>
          <TierCardDescriptionText>
            Min stake amount: ${price}&nbsp;in&nbsp;REBL
          </TierCardDescriptionText>
          <DownloadArea>
            <a href={`${tierNftPath}.m4v`} download>
              <Download />
            </a>
          </DownloadArea>
        </TierCardDescription>
      </TierCardInfoGrid>
    </TierCardWrapper>
  );
}
