import { useSelector } from 'react-redux';
import { parseUnits } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { State } from '../types';
import { Status } from '../../interfaces/statuses';
import { IStakingState, IStakingStateFormatted } from './interfaces/data.interface';
import { useTokenStateFormatted } from '../contract-token/hooks';

export const useStakingState = (): IStakingState => {
  return useSelector((state: State) => state.contractStaking);
};

export const useStakingStateFormatted = (): IStakingStateFormatted => {
  const stakingState = useStakingState();
  const tokenStateFormatted = useTokenStateFormatted();
  return useMemo(
    () => ({
      ...stakingState,
      rewards: stakingState.rewards.map((reward: number) => parseUnits(reward.toString(), 'wei')),
      stakingInfo: {
        usdtAmountForReward: parseUnits(
          stakingState.stakingInfo?.usdtAmountForReward.toString() || '0',
          18 - tokenStateFormatted.decimals,
        ),
        periodInWeeks: parseUnits(stakingState.stakingInfo?.periodInWeeks.toString() || '0', 'wei'),
        rewardUnblockTimestamp: parseUnits(
          stakingState.stakingInfo?.rewardUnblockTimestamp.toString() || '0',
          0,
        ),
        tokenAmount: parseUnits(
          stakingState.stakingInfo?.tokenAmount.toString() || '0',
          18 - tokenStateFormatted.decimals,
        ),
      },
    }),
    [stakingState, tokenStateFormatted],
  );
};

export const useStakingStateStatus = (): Status => {
  return useSelector((state: State) => state.contractStaking.stateStatus);
};
