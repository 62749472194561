import { useState, useEffect } from 'react';
import { formatBigNumber } from 'utils/formatStringNumber';
import { TreasuryInfo } from '../types';
import { useInvestmentInfo } from './useInvestmentInfo';
import { useTreasuryReservesInfo } from './useTreasuryReservesInfo';

export function useTreasuryInfo(): TreasuryInfo {
  const [treasuryTotalUsd, setTreasuryTotalUsd] = useState<string | null>(null);

  const reservesInfo = useTreasuryReservesInfo();
  const investmentInfo = useInvestmentInfo();

  useEffect(() => {
    if (!reservesInfo || !investmentInfo) return;

    const totalUsd = investmentInfo.totalUsd.add(reservesInfo.totalUsd);
    const formattedTotalUsd = formatBigNumber(totalUsd, { fractionalFlooring: 0 });
    setTreasuryTotalUsd(formattedTotalUsd);
  }, [reservesInfo, investmentInfo]);

  return {
    totalUsd: treasuryTotalUsd,
    reservesTable: reservesInfo,
    investmentsTable: investmentInfo,
  };
}
