import { LoadingBox } from 'components/Common/LoadingBox';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { formatBigNumber } from 'utils/formatStringNumber';
import { TreasuryTR, TreasuryTD, TreasuryTable, TreasuryValueTitle } from './styles';
import { TreasuryTableInfo } from '../types';

const TreasuryInvestmentArea = styled.div`
  grid-area: investment;
`;

type TreasuryInvesmentsProps = {
  investmentInfos: TreasuryTableInfo | null;
};

export function TreasuryInvesments({ investmentInfos }: TreasuryInvesmentsProps) {
  if (!investmentInfos) return <LoadingTreasuryInvesments />;

  const formattedTotalUsd = formatBigNumber(investmentInfos.totalUsd, { fractionalFlooring: 0 });

  return (
    <TreasuryInvestmentArea>
      <TreasuryValueTitle>TREASURY INVESTMENTS (INVESTMENT DAO)</TreasuryValueTitle>
      <TreasuryTable>
        <tbody>
          {investmentInfos.tokens.map((token, i) => (
            <TreasuryTR key={`treasure-investment-${i}`}>
              <TreasuryTD>{token.symbol}</TreasuryTD>
              <TreasuryTD>{token.tokenAmount}</TreasuryTD>
              <TreasuryTD>$ {token.usdAmount}</TreasuryTD>
            </TreasuryTR>
          ))}

          <TreasuryTR>
            <TreasuryTD>TOTAL</TreasuryTD>
            <TreasuryTD />
            <TreasuryTD>$ {formattedTotalUsd}</TreasuryTD>
          </TreasuryTR>
        </tbody>
      </TreasuryTable>
    </TreasuryInvestmentArea>
  );
}

const loadingInvestmentCount = 3;

function LoadingTreasuryInvesments() {
  const loadingInvestments: ReactNode[] = [];
  for (let i = 0; i < loadingInvestmentCount; i++)
    loadingInvestments.push(
      <TreasuryTR key={`treasury-reserved-${i}`}>
        <TreasuryTD>
          <LoadingBox width='5rem' height='1.5rem' />
        </TreasuryTD>
        <TreasuryTD>
          <LoadingBox width='10rem' height='1.5rem' />
        </TreasuryTD>
        <TreasuryTD>
          <LoadingBox width='7rem' height='1.5rem' />
        </TreasuryTD>
      </TreasuryTR>,
    );

  loadingInvestments.push(
    <TreasuryTR key={`treasury-reserved-${loadingInvestmentCount}`}>
      <TreasuryTD>
        <LoadingBox width='5rem' height='1.5rem' />
      </TreasuryTD>
      <TreasuryTD />
      <TreasuryTD>
        <LoadingBox width='11rem' height='1.5rem' />
      </TreasuryTD>
    </TreasuryTR>,
  );

  return (
    <TreasuryInvestmentArea>
      <TreasuryValueTitle>TREASURY INVESTMENTS (INVESTMENT DAO)</TreasuryValueTitle>
      <TreasuryTable>
        <tbody>{loadingInvestments.map((elem) => elem)}</tbody>
      </TreasuryTable>
    </TreasuryInvestmentArea>
  );
}
