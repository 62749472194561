import React from 'react';
import { Box, CardMedia, Typography, useTheme } from '@mui/material';

interface Props {
  title: string;
  description: string;
  img: string;
  score: number;
}

export const ScoreNFT = ({ title, description, img, score }: Props): React.ReactElement => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 400,
        mt: 3,
        marginX: 1,
      }}
    >
      <Typography
        sx={{
          display: 'block',
          width: '100%',
          textAlign: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          position: 'relative',
        }}
      >
        <CardMedia
          sx={{
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
          }}
          component='img'
          height='400'
          image={img}
          alt={title}
        />
        <Box
          sx={{
            p: 1,
            backgroundColor: '#fff',
            color: '#000',
            borderEndEndRadius: theme.shape.borderRadius,
            borderStartStartRadius: theme.shape.borderRadius,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          x{score}
        </Box>
      </Typography>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            marginTop: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          component='div'
          variant='body1'
          sx={{
            opacity: '.5',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};
