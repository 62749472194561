import { LoadingBox } from 'components/Common/LoadingBox';
import styled from 'styled-components';

const TreasuryTotalArea = styled.div`
  grid-area: total;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: fit-content;
`;

const TreasuryValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 2rem 4rem;

  background-color: ${({ theme }) => theme.bg1};
  border-radius: 0.5rem;

  /* box-shadow: inset 0px 0px 30px ${({ theme }) => theme.shadow1}; */
`;
const TotalValueTitle = styled.h3`
  margin: 0 auto 0.5rem;
  text-align: center;
  font-size: 0.8rem;

  width: fit-content;
`;
const TotalValueDescription = styled.p`
  margin: 0;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;

  color: ${({ theme }) => theme.primary1};
  text-shadow: 0 0 10px ${({ theme }) => theme.shadow1};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 2rem;
  `}
`;

type TotalTreasuryProps = {
  totalValue: string | null;
};

export function TotalTreasury({ totalValue }: TotalTreasuryProps) {
  if (!totalValue) return <LoadingTotalTreasury />;

  return (
    <TreasuryTotalArea>
      <TreasuryValueWrapper>
        <TotalValueTitle>TOTAL TREASURY VALUE</TotalValueTitle>
        <TotalValueDescription>{totalValue}$</TotalValueDescription>
      </TreasuryValueWrapper>
    </TreasuryTotalArea>
  );
}

function LoadingTotalTreasury() {
  return (
    <TreasuryTotalArea>
      <TreasuryValueWrapper>
        <TotalValueTitle>TOTAL TREASURY VALUE</TotalValueTitle>
        <LoadingBox width='17rem' height='3rem' margin='0.3rem auto 0' />
      </TreasuryValueWrapper>
    </TreasuryTotalArea>
  );
}
