import { createReducer } from '@reduxjs/toolkit';
import { initialCommonState } from '../../interfaces/common-state.interface';
import { INFTState } from './interfaces/data.interface';
import { Status } from '../../interfaces/statuses';
import { fetchNFT, fetchNFTError, fetchNFTSuccess } from './actions';

const initialState: INFTState = {
  ...initialCommonState,
  amountByLevel: [0, 0, 0, 0, 0],
  daoInfo: {
    daoStatus: 'Recruite',
    daoPower: 0,
    totalDaoPower: 0,
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchNFT, (state) => ({
      ...state,
      stateStatus: Status.PENDING,
    }))
    .addCase(fetchNFTSuccess, (state, { payload }) => ({
      ...state,
      ...payload,
      stateStatus: Status.SUCCESS,
      error: null,
    }))
    .addCase(fetchNFTError, (state, { payload }) => ({
      ...state,
      stateStatus: Status.ERROR,
      error: payload.error,
    })),
);
