import { Router2 } from 'config/constants/abis/types/Router2';
import { Contract } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { useMemo } from 'react';
import { getNFTContractV2, getRouter2Contract, getWethContract } from 'utils/contact';
import { Erc20, Nft2 } from 'config/constants/abis/types';
import useActiveWeb3React from './useActiveWeb3React';
import useDebounce from './useDebounce';

export function useRouter2Contract(): Router2 | null {
  return useContract<Router2>(getRouter2Contract);
}

export function useWethContract(): Erc20 | null {
  return useContract<Erc20>((library: Web3Provider, account?: string) =>
    getWethContract(library, account),
  );
}

export function useNFTContractV2(): Nft2 | null {
  return useContract<Nft2>(getNFTContractV2);
}

export function useContract<T = Contract>(
  getContractFunction: (library: Web3Provider, account?: string) => T,
): T | null {
  const { account, chainId, library } = useActiveWeb3React();

  return useDebounce(
    useMemo((): T | null => {
      if (!library) {
        return null;
      }

      const contract: T | null = getContractFunction(library, account ?? undefined);
      if (!contract) {
        return null;
      }

      return contract;
    }, [getContractFunction, account, chainId, library]),
    100,
  );
}
