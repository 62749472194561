import { Dispatch } from '@reduxjs/toolkit';
import { Nft2 } from 'config/constants/abis/types';
import { daoStatuses } from 'config/constants/nft';
import { IMulticallState } from '../contract-multicall/interfaces/data.interface';
import { fetchNFT as fetchNFTstate, fetchNFTError, fetchNFTSuccess } from './actions';

export const fetchNFTThunk =
  (contract: Nft2, tokenIds: IMulticallState['owned'], account: string) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchNFTstate());

    async function fetchNFT() {
      const nftResp = await Promise.all(tokenIds.map((id) => contract.tokenLevels(id)));
      const amountByLevel = [0, 0, 0, 0, 0];

      nftResp.forEach((level) => {
        amountByLevel[level.toNumber()]++;
      });

      const [daoStatusResp, daoPowerBN, totalDaoPowerBN] = await Promise.all([
        contract.getDAOStatusOf(account),
        contract.getPowerOf(account),
        contract.getTotalVotingPower(),
      ]);
      const daoStatus = daoStatuses[daoStatusResp.toNumber()];
      const daoPower = daoPowerBN.toNumber();
      const totalDaoPower = totalDaoPowerBN.toNumber();

      dispatch(fetchNFTSuccess({ amountByLevel, daoInfo: { daoStatus, daoPower, totalDaoPower } }));
    }

    try {
      fetchNFT();
    } catch (error) {
      console.error(error);
      dispatch(fetchNFTError({ error: error as Error }));
    }
  };
