import { Menu } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { socialLinks } from 'config/constants/social';
import {
  MainNavigationWrapper,
  MainNavigationHeading,
  MainNavList,
  MainNavLI,
  SocialLinkList,
  SocialLinkAnchor,
  LogoImg,
  Divider,
  MenuAnchorsContainer,
  CloseMenuButton,
} from './styles';
import {
  DaoForumIcon,
  DaoInvestmentIcon,
  DashboardIcon,
  DocsIcon,
  MyNftIcon,
  NftMarketIcon,
  NftRewardIcon,
  StakeIcon,
} from './icons';

type MainNavigationProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

export function MainNavigation({ isOpen, setIsOpen }: MainNavigationProps) {
  return (
    <MainNavigationWrapper isOpen={isOpen}>
      <CloseMenuButton onClick={() => setIsOpen(!isOpen)}>
        <Menu color='currentColor' />
      </CloseMenuButton>
      <LogoImg src='/images/nav-logo.png' alt='logo' />
      <MainNavigationHeading>REBELLION PROTOCOL</MainNavigationHeading>
      <MenuAnchorsContainer>
        <nav>
          <MainNavList>
            <MainNavLI>
              <NavLink to='/dashboard'>
                <DashboardIcon /> Dashboard
              </NavLink>
            </MainNavLI>
            <MainNavLI>
              <NavLink to='/stake'>
                <StakeIcon /> Stake
              </NavLink>
            </MainNavLI>
            <MainNavLI>
              <NavLink to='/my-nfts'>
                <MyNftIcon /> My NFTs
              </NavLink>
            </MainNavLI>
            <MainNavLI>
              <NavLink to='/tiers'>
                <NftRewardIcon />
                NFT Reward Tiers
              </NavLink>
            </MainNavLI>
          </MainNavList>
        </nav>
        <Divider />
        <MainNavList>
          <MainNavLI>
            <a target='_blank' rel='noreferrer' href='https://snapshot.org/#/rebellionprotocol.eth'>
              <DaoInvestmentIcon /> Governance
            </a>
          </MainNavLI>
          <MainNavLI>
            <a target='_blank' rel='noreferrer' href='https://t.me/+h3MhQJDiaRo5ZDI0'>
              <DaoForumIcon /> DAO Forum
            </a>
          </MainNavLI>
          <MainNavLI>
            <a target='_blank' rel='noreferrer' href='https://opensea.io/collection/rebellion-protocol-1'>
              <NftMarketIcon /> NFT marketplace
            </a>
          </MainNavLI>
          <MainNavLI>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://wp.rebellionprotocol.com/rebellion-vc-fund'
            >
              <DocsIcon /> Docs
            </a>
          </MainNavLI>
        </MainNavList>
      </MenuAnchorsContainer>

      <SocialLinkList>
        {socialLinks.map((link, i) => (
          <li key={`social-link-${i}`}>
            <SocialLinkAnchor href={link.href}>{link.icon}</SocialLinkAnchor>
          </li>
        ))}
      </SocialLinkList>
    </MainNavigationWrapper>
  );
}
