import { Web3Provider } from '@ethersproject/providers';
import { connectionConfig } from 'config/constants';
import { invesmentChainIdList, InvestmentChainId } from 'config/constants/investment';
import { BigNumber } from 'ethers';
import { useState, useEffect } from 'react';
import { getChainRouter02Contract, getERC20Contract } from 'utils/contact';
import { formatBigNumber } from 'utils/formatStringNumber';
import { getChainUsdPrice } from 'utils/getUsdPrice';
import { TreasuryTableInfo, TreasuryTokenInfo } from '../types';
import { useInvestmentChainDataList } from './useInvestmentChainDataList';

export function useInvestmentInfo(): TreasuryTableInfo | null {
  const [invesmentsInfos, setInvesmentsInfos] = useState<TreasuryTableInfo | null>(null);
  const investmentChainDataList = useInvestmentChainDataList();

  useEffect(() => {
    if (!investmentChainDataList) return;

    // invesments will accumulate here
    let totalUsdAmount = BigNumber.from(0);

    // returns token infos for one chain
    async function fetchChainInvestments(chainId: InvestmentChainId) {
      const investmentData = investmentChainDataList![chainId];

      const router02Contract = getChainRouter02Contract(
        investmentData.provider as Web3Provider,
        investmentData.router02Address,
      );

      const investmentAddresses = Object.keys(investmentData.tokenList);

      const tokenAmountPromises = investmentAddresses.map((address) => {
        if (address === investmentData.wethAddress)
          return investmentData.provider.getBalance(connectionConfig.addresses.treasury);

        const contract = getERC20Contract(investmentData.provider as Web3Provider, address);
        return contract.balanceOf(connectionConfig.addresses.treasury);
      });

      const usdPricePromises = investmentAddresses.map((address) =>
        getChainUsdPrice(router02Contract, address, investmentData),
      );

      const tokenAmountResps = await Promise.all(tokenAmountPromises);
      const usdPriceResps = await Promise.all(usdPricePromises);

      const chainTokenInfos = investmentAddresses.reduce((prev, cur, i) => {
        const usdDecimals = investmentData.usdDecimals ?? 18;
        const usdBigOne = BigNumber.from(10).pow(usdDecimals);
        const tokenAmount = tokenAmountResps[i];
        const usdPrice = usdPriceResps[i];

        const usdAmount = tokenAmount.mul(usdPrice).div(usdBigOne);
        totalUsdAmount = totalUsdAmount.add(usdAmount);

        const formattedTokenAmount = formatBigNumber(tokenAmount, { fractionalFlooring: 3 });
        const formattedUsdAmount = formatBigNumber(usdAmount, { fractionalFlooring: 3 });

        // console.log(
        //   `${investmentAddresses[i]}\nprice:${usdPrice}\ntoken:${formattedTokenAmount}\nusds:${formattedUsdAmount}`,
        // );

        prev.push({
          symbol: investmentData.tokenList[cur],
          tokenAmount: formattedTokenAmount,
          usdAmount: formattedUsdAmount,
        });

        return prev;
      }, [] as TreasuryTokenInfo[]);

      return chainTokenInfos;
    }

    // self invoking function
    (async function () {
      const tokenInfos: TreasuryTokenInfo[] = [];

      await Promise.all(
        invesmentChainIdList.map((chainId) =>
          fetchChainInvestments(chainId).then((chainTokenInfo) =>
            tokenInfos.push(...chainTokenInfo),
          ),
        ),
      );

      setInvesmentsInfos({
        tokens: tokenInfos,
        totalUsd: totalUsdAmount,
      });
    })();
  }, [investmentChainDataList]);

  return invesmentsInfos;
}
