import { connectionConfig } from 'config/constants';
import { DEAD_ADDRESS } from 'config/constants/addresses';
import { BIG_ETH_ONE } from 'config/constants/bignumber';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useRouter2Contract } from 'hooks/useContract';
import { useTokenStaking as useStakingTokenAmount } from 'hooks/useTokenStaking';
import { useState, useEffect } from 'react';
import { getWethContract } from 'utils/contact';
import { getTokenContract } from 'utils/contractOld';
import { formatBigNumber } from 'utils/formatStringNumber';
import { getUsdPrice } from 'utils/getUsdPrice';
import { DashboardTokenInfo } from '../types';

export function useDashboardDisplayInfo(): DashboardTokenInfo | null {
  const { library } = useActiveWeb3React();
  const [dashboardInfo, setDashboardInfo] = useState<DashboardTokenInfo | null>(null);
  const router2Contract = useRouter2Contract();
  const stakingTokenAmount = useStakingTokenAmount();

  useEffect(() => {
    if (!library || !stakingTokenAmount || !router2Contract) return;

    const tokenContract = getTokenContract(library);
    const wethContract = getWethContract(library);

    const fetchDashboardInfo = async function () {
      const [totalSupply, burned, pairTokenBalance, tokenPrice, wethPrice] = await Promise.all([
        tokenContract.totalSupply(),
        tokenContract.balanceOf(DEAD_ADDRESS),
        wethContract.balanceOf(connectionConfig.addresses.tokenWethPair),
        getUsdPrice(connectionConfig.token.address, router2Contract),
        getUsdPrice(connectionConfig.addresses.weth, router2Contract),
      ]);

      const capitalization = totalSupply.mul(tokenPrice).div(BIG_ETH_ONE.toString());
      // const circulation = totalSupply.sub(burned).mul(tokenPrice).div(BIG_ETH_ONE.toString());
      const circulation = totalSupply.sub(burned);
      const liquidity = pairTokenBalance;
      const liquidityUsd = pairTokenBalance.mul(wethPrice).div(BIG_ETH_ONE.toString());

      // const formattedTokenPrice = formatBigNumber(tokenPrice, {
      //   fractionalFlooring: 8,
      // });
      const formattedCapitalization = formatBigNumber(capitalization, {
        fractionalFlooring: 0,
      });
      const formattedCirculation = formatBigNumber(circulation, {
        fractionalFlooring: 0,
      });
      const formattedBurned = formatBigNumber(burned, {
        fractionalFlooring: 0,
      });
      const formattedTotalSupply = formatBigNumber(totalSupply, {
        fractionalFlooring: 0,
      });
      const formattedLiquidity = formatBigNumber(liquidity, { fractionalFlooring: 0 });
      const formattedLiquidityUsd = formatBigNumber(liquidityUsd, { fractionalFlooring: 0 });

      setDashboardInfo({
        // priceUsd: `$ ${formattedTokenPrice}`,
        capitalization: `$ ${formattedCapitalization}`,
        circulation: `${formattedCirculation}`,
        wethLiquidity: `${formattedLiquidity} BNB ($ ${formattedLiquidityUsd})`,
        totalSupply: formattedTotalSupply,
        staked: stakingTokenAmount,
        burned: formattedBurned,
      });
    };
    try {
      fetchDashboardInfo();
    } catch (e) {
      console.error('Error while fetching dashboard info\n', e);
    }
  }, [router2Contract, library, stakingTokenAmount]);
  return dashboardInfo;
}
