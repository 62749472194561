import styled from 'styled-components';

export const H1 = styled.h1`
  margin: 0;
  text-transform: uppercase;
  color: #3c3950;
  font-size: 2.5rem;
`;

export const H2 = styled.h2`
  margin: 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.text1};
  font-size: 2rem;
  text-align: center;
  text-shadow: 0 0 10px ${({ theme }) => theme.shadow2};
`;
