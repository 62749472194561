import { Container, Grid } from '@mui/material';
import { BlockTiers } from 'pages/TiersPage/BlockTiers';

function TiersPage() {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <BlockTiers />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TiersPage;
