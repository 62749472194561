import { connectionConfig, treasuryTokens } from 'config/constants';
import { BIG_ETH_ONE } from 'config/constants/bignumber';
import { BigNumber } from 'ethers';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useRouter2Contract } from 'hooks/useContract';
import { useState, useEffect } from 'react';
import { getERC20Contract } from 'utils/contact';
import { formatBigNumber } from 'utils/formatStringNumber';
import { getUsdPrice } from 'utils/getUsdPrice';
import { TreasuryTableInfo, TreasuryTokenInfo } from '../types';

export function useTreasuryReservesInfo(): TreasuryTableInfo | null {
  const [treasuryInfo, setTreasuryInfo] = useState<TreasuryTableInfo | null>(null);

  const { library } = useActiveWeb3React();
  const router2Contract = useRouter2Contract();

  useEffect(() => {
    if (!library || !router2Contract) return;

    const tokenContracts = treasuryTokens.map((address) => getERC20Contract(library, address));

    const fetchTreasuryInfo = async function () {
      const treasuryBalances = await Promise.all(
        tokenContracts.map((contract) => contract.balanceOf(connectionConfig.addresses.treasury)),
      );

      const usdPrices = await Promise.all(
        treasuryTokens.map((address) => getUsdPrice(address, router2Contract)),
      );
      const usdAmounts = usdPrices.map((usdPrice, i) =>
        treasuryBalances[i].mul(usdPrice).div(BIG_ETH_ONE.toString()),
      );

      const treasuryTotalValueBN = usdAmounts.reduce(
        (prev, cur) => prev.add(cur),
        BigNumber.from(0),
      );

      const treasurySymbols = await Promise.all(
        tokenContracts.map((contract) => contract.symbol()),
      );
      const reserveInfos: TreasuryTokenInfo[] = treasurySymbols.map((symbol, i) => ({
        symbol,
        tokenAmount: formatBigNumber(treasuryBalances[i], { fractionalFlooring: 2 }),
        usdAmount: formatBigNumber(usdAmounts[i], { fractionalFlooring: 2 }),
      }));

      setTreasuryInfo({
        totalUsd: treasuryTotalValueBN,
        tokens: reserveInfos,
      });
    };

    try {
      fetchTreasuryInfo();
    } catch (e) {
      console.error('Error while fetching TreasuryInfo\n', e);
    }
  }, [library, router2Contract]);

  return treasuryInfo;
}
