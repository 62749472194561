import { DaoStatus } from 'state/contract-nft/interfaces/data.interface';
import { tiers, usdtAmountForTier } from 'utils/calcHelper';

type TierInfo = {
  title: string;
  rarity: string;
  usdPrice: number;
  votingPower: number;
};

export const ProtectorVotesPowerThreshold = 1000;

export const daoStatuses: DaoStatus[] = ['Recruite', 'Rebel', 'Protector'];

export const tierInfos: TierInfo[] = [
  {
    title: tiers[0],
    rarity: 'Uncommon',
    usdPrice: usdtAmountForTier[0],
    votingPower: 3,
  },
  {
    title: tiers[1],
    rarity: 'Rare',
    usdPrice: usdtAmountForTier[1],
    votingPower: 15,
  },
  {
    title: tiers[2],
    rarity: 'Epic',
    usdPrice: usdtAmountForTier[2],
    votingPower: 50,
  },
  {
    title: tiers[3],
    rarity: 'Legendary',
    usdPrice: usdtAmountForTier[3],
    votingPower: 250,
  },
  {
    title: tiers[4],
    rarity: 'Mythical',
    usdPrice: usdtAmountForTier[4],
    votingPower: 1000,
  },
];
