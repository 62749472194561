import { useMemo } from 'react';
import { Contract } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import useActiveWeb3React from './useActiveWeb3React';
import {
  getMulticallContract,
  getNFTContract,
  getStakingContract,
  getStakingContractV2,
  getTokenContract,
} from '../utils/contractOld';
import useDebounce from './useDebounce';
import { ITokenContract } from '../interfaces/token-contract.interface';
import { INFTContract } from '../state/contract-nft/interfaces/contract.interface';
import {
  IStakingContract,
  IStakingContractV2,
} from '../state/contract-staking/interfaces/contract.interface';
import { IMulticallContract } from '../state/contract-multicall/interfaces/contract.interface';

export function useMulticallContract(): IMulticallContract | null {
  return useContract<IMulticallContract>(getMulticallContract);
}

export function useNFTContract(): INFTContract | null {
  return useContract<INFTContract>(getNFTContract);
}

export function useTokenContract(): ITokenContract | null {
  return useContract<ITokenContract>(getTokenContract);
}

export function useStakingContract(): IStakingContract | null {
  return useContract<IStakingContract>(getStakingContract);
}

export function useStakingContractV2(): IStakingContractV2 | null {
  return useContract<IStakingContractV2>(getStakingContractV2);
}

export function useContract<T = Contract>(
  getContractFunction: (library: Web3Provider, account?: string) => T,
): T | null {
  const { account, chainId, library } = useActiveWeb3React();

  return useDebounce(
    useMemo((): T | null => {
      if (!library) {
        return null;
      }

      const contract: T | null = getContractFunction(library, account ?? undefined);
      if (!contract) {
        return null;
      }

      return contract;
    }, [getContractFunction, account, chainId, library]),
    100,
  );
}
