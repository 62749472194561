import styled from 'styled-components';

export const TreasuryValueTitle = styled.h3`
  margin: 0;
  padding: 0.75rem 1rem 0.5rem;
  text-align: center;
  font-size: 0.8rem;

  background-color: ${({ theme }) => theme.bg3};
  /* box-shadow: inset 0px 0px 30px ${({ theme }) => theme.shadow1}; */

  width: 100%;
  box-sizing: border-box;

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;

export const TreasuryTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-size: 1.2rem;

  background-color: ${({ theme }) => theme.bg1};
  /* border: 1px solid ${({ theme }) => theme.bg3}; */
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 0.9rem;
  `}
`;
export const TreasuryTR = styled.tr`
  width: 100%;
  &:first-child td {
    padding-top: 1rem;
  }
  &:nth-last-child(2) td {
    padding-bottom: 1rem;
  }
  &:last-child td {
    padding-bottom: 1rem;

    border-top: 3px solid ${({ theme }) => theme.bg3};
    padding-top: 1rem;
  }
`;
export const TreasuryTD = styled.td`
  padding: 0.5rem 1rem;
  white-space: nowrap;

  &:first-child {
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 2rem;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    white-space: normal;
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
  &:first-child {
    width: auto;
  }
  &:nth-child(2) {
    display: none;
  }
  &:last-child {
    width: 99%;
  }
  `}
`;
