import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { State } from '../types';
import { Status } from '../../interfaces/statuses';
import { DaoInfo, INFTState, INFTStateFormatted } from './interfaces/data.interface';

export const useNFTState = (): INFTState => {
  return useSelector((state: State) => state.contractNFT);
};

export const useNFTStateFormatted = () => {
  const nftState = useNFTState();
  return useMemo<INFTStateFormatted>(() => {
    return {
      ...nftState,
    };
  }, [nftState]);
};

export const useNFTStateStatus = (): Status => {
  return useSelector((state: State) => state.contractNFT.stateStatus);
};

export function useDaoInfo(): DaoInfo {
  // return {
  //   daoStatus: 'Protector',
  //   daoPower: 123,
  //   totalDaoPower: 3998,
  // };
  return useSelector((state: State) => state.contractNFT.daoInfo);
}
