import { Dispatch } from '@reduxjs/toolkit';
import { ITokenContract } from '../../interfaces/token-contract.interface';
import { simpleRpcProvider } from '../../utils/providers';
import { connectionConfig } from '../../config/constants';
import { ITokenData } from './interfaces/data.interface';
import { fetchToken, fetchTokenError, fetchTokenSuccess } from './actions';

export const fetchTokenThunk =
  (tokenContract: ITokenContract, account: string) => async (dispatch: Dispatch) => {
    dispatch(fetchToken());
    try {
      Promise.all([
        tokenContract.balanceOf(account),
        tokenContract.symbol(),
        tokenContract.name(),
        tokenContract.decimals(),
        simpleRpcProvider.getBalance(account),
        tokenContract.allowance(account, connectionConfig.staking3.address),
      ])
        .then((value) => {
          // console.log(value);
          const tokenData: ITokenData = {
            balance: value[0].toString(),
            symbol: value[1],
            name: value[2],
            decimals: Number(value[3].toString()),
            networkBalance: value[4].toString(),
            allowance: value[5].toString(),
          };
          dispatch(fetchTokenSuccess({ tokenData }));
        })
        .catch((error: Error) => {
          dispatch(fetchTokenError({ error }));
        });
    } catch (error) {
      console.error(error);
      dispatch(fetchTokenError({ error: error as Error }));
    }
  };
