import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { BIGNUMBER_SEPARATOR } from 'config/constants/misc';

type FormatBigNumberOptions = FormatNumberStringOptions & {
  decimals?: number;
};

export function formatBigNumber(wei: BigNumberish, options?: FormatBigNumberOptions): string {
  const bn = BigNumber.from(wei);
  const etherFormatted = formatUnits(bn, options?.decimals ?? 18);

  return formatNumberString(etherFormatted, options);
}

type FormatNumberStringOptions = {
  keepFractionalZero?: boolean;
  separator?: string;
  fractionalFlooring?: number;
};

export function formatNumberString(value: string, options?: FormatNumberStringOptions) {
  const [integer, fractional] = value.split('.');

  // format int
  const formattedInt = putSeparators(integer, 3, options?.separator ?? BIGNUMBER_SEPARATOR, true);

  if (!fractional) return formattedInt;

  if (fractional === '0')
    return options?.keepFractionalZero === true ? `${formattedInt}.${fractional}` : formattedInt;

  if (options?.fractionalFlooring === 0) return formattedInt;

  // format fractional
  const fractionalToFormat = options?.fractionalFlooring
    ? fractional.substring(0, options.fractionalFlooring)
    : fractional;
  const formattedFractional = putSeparators(
    fractionalToFormat,
    3,
    options?.separator ?? BIGNUMBER_SEPARATOR,
    false,
  );

  return `${formattedInt}.${formattedFractional}`;
}

function putSeparators(str: string, step: number, separator = ' ', reverse = false): string {
  const formatSeparation = reverse
    ? (first: string, second: string) => `${second}${separator}${first}`
    : (first: string, second: string) => `${first}${separator}${second}`;
  const getDirectionalSlice = reverse
    ? (target: string, sliceCount: number) =>
        target.slice(-step * (sliceCount + 1), -step * sliceCount)
    : (target: string, sliceCount: number) =>
        target.slice(step * sliceCount, step * (sliceCount + 1));

  const sliceCount = Math.ceil(str.length / step);
  let result = reverse ? str.slice(-step) : str.slice(0, step);
  for (let i = 1; i < sliceCount; i++) {
    const slice = getDirectionalSlice(str, i);
    result = formatSeparation(result, slice);
  }

  return result;
}

export function floorStringNumber(num: string, rounding: number) {
  const [int, fractional] = num.split('.');
  if (!fractional) return num;

  const formattedFractional = fractional.substring(0, rounding);

  return `${int}.${formattedFractional}`;
}
