import styled from 'styled-components';
import { DashboardSection } from './DashboardSection';
import { useDashboardDisplayInfo } from './hooks/useDashboardDisplayInfo';
import { TreasurySection } from './TreasurySection';

export const DashboardPageWrapper = styled.div`
  margin: 0 0 4rem;

  display: flex;
  flex-direction: column;
  gap: 5rem;

  max-width: 1000px;
  margin: 0 auto;
`;

function DashboardPage() {
  const dashboardDisplayInfos = useDashboardDisplayInfo();

  return (
    <DashboardPageWrapper>
      <h1 className='visually-hidden'>Dashboard Page</h1>
      <DashboardSection displayInfo={dashboardDisplayInfos} />
      <TreasurySection />
    </DashboardPageWrapper>
  );
  // return null;
}

export default DashboardPage;
