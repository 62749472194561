import { Dispatch } from '@reduxjs/toolkit';
import { IMulticallContract } from './interfaces/contract.interface';
import { fetchMulticall, fetchMulticallError, fetchMulticallSuccess } from './actions';
import { connectionConfig } from '../../config/constants';

export const fetchMulticallThunk =
  (contract: IMulticallContract, account: string) => async (dispatch: Dispatch) => {
    dispatch(fetchMulticall());
    try {
      contract
        .getIdByContractAndOwnerPure(connectionConfig.nft2.address, account, 0, 10000)
        .then((value) => {
          dispatch(fetchMulticallSuccess({ owned: value.map((id) => id.toString()) }));
        })
        .catch((error: Error) => {
          dispatch(fetchMulticallError({ error }));
        });
    } catch (error) {
      console.error(error);
      dispatch(fetchMulticallError({ error: error as Error }));
    }
  };
