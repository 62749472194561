import styled from 'styled-components';

type MainContentWrapperProps = {
  isMenuOpen: boolean;
};
export const MainContentWrapper = styled.div<MainContentWrapperProps>`
  position: relative;

  margin-left: 19rem;
  padding: 2rem 2rem 1rem;

  transition: margin-left 1s;

  ${({ isMenuOpen }) =>
    !isMenuOpen &&
    `
    margin-left: 5rem;
  `}

  @media (max-width: 1000px) {
    margin-left: 3rem;
  }
`;

export const LogoImg = styled.img`
  margin: 0 auto;

  height: 10rem;
`;

export const MainNavigationHeading = styled.h2`
  color: ${({ theme }) => theme.primary1};
  font-size: 1.3rem;
  text-align: center;
`;

export const MenuAnchorsContainer = styled.div`
  a {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    padding: 0.5rem 0 0.5rem 2rem;
    transition: var(--open-anim-time) all ease;

    color: currentColor;
    text-decoration: none;

    & svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: currentColor;
      margin-left: 0;
      margin-right: 1rem;
      transition: var(--open-anim-time) width ease, var(--open-anim-time) margin ease;
    }

    @media (max-width: 1000px) {
      & svg {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }
`;

export const Divider = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 1rem 0 1rem 2rem;

  border-bottom: 1px solid white;
`;

export const MainNavList = styled.ul`
  font-family: 'Source Sans Pro', sans-serif;

  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;
export const MainNavLI = styled.li`
  border-radius: 10px;

  color: ${({ theme }) => theme.text1};

  &:hover {
    color: ${({ theme }) => theme.text5};
    background-color: ${({ theme }) => theme.bg5};
  }

  &:active {
    color: ${({ theme }) => theme.bg5};
    background-color: transparent;
  }

  @media (max-width: 1000px) {
    border-radius: 0;
  }
`;

export const CloseMenuButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1.5rem;

  background: none;
  border: none;

  color: ${({ theme }) => theme.text1};
  transition: opacity 0.5s !important;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  &:active {
    opacity: 0.3;
  }

  @media (max-width: 1000px) {
    right: 0.5rem;
  }
`;

export const SocialLinkList = styled.ul`
  list-style: none;
  margin: auto 0 0;
  padding: 3rem 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  flex-wrap: wrap;

  color: ${({ theme }) => theme.primary2};
`;

export const SocialLinkAnchor = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noreferrer',
}))`
  color: currentColor;

  display: block;
  width: 2rem;
  height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    transition: width 0.5s, height 0.5s;
  }

  &:hover {
    opacity: 0.8;

    & svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

type MenuOpenProps = { isOpen: boolean };
export const MainNavigationWrapper = styled.div<MenuOpenProps>`
  box-sizing: border-box;
  height: 100vh;
  width: 19rem;
  position: fixed;
  -webkit-backface-visibility: hidden;
  top: 0;
  left: 0;
  z-index: 10;

  overflow-x: hidden;

  padding: 2rem 1rem 1rem;

  display: flex;
  flex-direction: column;

  /* background-color: #24262e; */
  background-color: ${({ theme }) => theme.bg2};

  /* hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  --open-anim-time: 1s;

  transition: var(--open-anim-time) all ease;
  ${LogoImg} {
    transition: height var(--open-anim-time);
  }

  ${MainNavigationHeading} {
    transition: font-size var(--open-anim-time);
  }

  ${Divider} {
    transition: margin-left var(--open-anim-time);
  }

  * {
    transition: all var(--open-anim-time) ease, background-color 1ms, color 1ms;
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    width: 5rem;
    padding: 5rem 0 1rem;
    
    ${LogoImg} {
      height: 0;
    }

    ${MainNavigationHeading} {
      font-size: 0;
    }
    
    ${Divider} {
      margin-left: 0;
    }

    a {
      font-size: 0;
    }

    ${MenuAnchorsContainer}{
      a {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        svg {
          margin-left: 4px;
          margin-right: 0;
        }
      }
    }

    ${MainNavList}{
      padding-left: 1rem;
    }

    ${SocialLinkAnchor}{
      & svg {
        width: 1.2rem;
        height: 1.2rem;
      }
      
      &:hover {
        & svg {
          width: 1.7rem;
          height: 1.7rem;
        }
      }
    }
  `};

  @media (max-width: 1000px) {
    width: 100vw;
    height: 100vh;

    background-color: ${({ theme }) => theme.bg1};

    ${({ isOpen, theme }) =>
      !isOpen &&
      `
      width: 3rem;
      background-color: ${theme.bg2};
      
      ${MenuAnchorsContainer}{
        a {
          padding-left: 0.5rem;

          svg {
            margin: 0;
          }
        }
      }

      ${MainNavList}{
        padding-left: 0;
      }
    `}
  }
`;
