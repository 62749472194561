import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Close from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { PrimaryButton, SecondaryButton } from 'components/Common/Button';
import { ConnectorNames, connectorNamesArray, walletsByName } from '../../utils/web3React';
import useAuth from '../../hooks/useAuth';

const DialogTitleLine = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface Props {
  text?: string;
}

export default function WalletModal({ text }: Props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { login, logout } = useAuth();
  const { account } = useWeb3React();

  useEffect(() => {
    if (account && open) {
      handleClose();
    }
  }, [account, open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {account ? (
        <SecondaryButton onClick={() => logout()}>Disconnect</SecondaryButton>
      ) : (
        <PrimaryButton onClick={handleClickOpen}>{text || 'Connect'}</PrimaryButton>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          <DialogTitleLine>
            <div>Connect your wallet</div>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitleLine>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {connectorNamesArray.map((key: ConnectorNames, index: number, array) => {
              const option = walletsByName[key];
              return (
                <React.Fragment key={key}>
                  <ListItemButton alignItems='center' onClick={() => login(key)}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{}}
                        alt={option.name}
                        src={`/images/wallets/${option.iconName}`}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={option.name} secondary={option.description} />
                  </ListItemButton>

                  <Divider
                    variant='inset'
                    component='li'
                    sx={{ display: index === array.length - 1 ? 'none' : 'block' }}
                  />
                </React.Fragment>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
