import React, { ReactNode, useMemo } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals'
import useActiveWeb3React from './hooks/useActiveWeb3React';
import { BLOCKED_ADDRESSES } from './config/constants';
import Providers from './providers';
import 'index.css';

function BlockList({ children }: { children: ReactNode }) {
  const { account } = useActiveWeb3React();
  const blocked: boolean = useMemo(
    () => Boolean(account && BLOCKED_ADDRESSES.indexOf(account)),
    [account],
  );
  if (blocked) {
    return <div>Blocked address</div>;
  }
  return <>{children}</>;
}

ReactDOM.render(
  <React.StrictMode>
    <BlockList>
      <Providers>
        <App />
      </Providers>
    </BlockList>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
