import { connectionConfig } from 'config/constants';
import { Router2 } from 'config/constants/abis/types';
import { BIG_ETH_ONE } from 'config/constants/bignumber';
import { BigNumber } from 'ethers';

export async function getUsdPrice(
  tokenAddress: string,
  router02Contract: Router2,
): Promise<BigNumber> {
  return getChainUsdPrice(router02Contract, tokenAddress, {
    wethAddress: connectionConfig.addresses.weth,
    usdAddress: connectionConfig.addresses.usd,
  });
}

// returns price of token in relativeToken currency
export async function getTokenPrice(
  router02Contract: Router2,
  tokenAddress: string,
  relativeTokenAddress: string,
) {
  const resp = await router02Contract.getAmountsOut(BIG_ETH_ONE.toString(), [
    tokenAddress,
    relativeTokenAddress,
  ]);

  return resp[1];
}

type ChainData = {
  wethAddress: string;
  usdAddress: string;
};
export async function getChainUsdPrice(
  router02Contract: Router2,
  tokenAddress: string,
  chainData: ChainData,
) {
  if (tokenAddress === chainData.usdAddress) return BIG_ETH_ONE;

  const path =
    tokenAddress === chainData.wethAddress
      ? [chainData.wethAddress, chainData.usdAddress]
      : [tokenAddress, chainData.wethAddress, chainData.usdAddress];

  const resp = await router02Contract.getAmountsOut(BIG_ETH_ONE.toString(), path);
  return resp[resp.length - 1];
}
