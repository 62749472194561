import { H2 } from 'components/Common/Headers';
import { LoadingBox } from 'components/Common/LoadingBox';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { DashboardTokenInfo } from './types';

const DashboardSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  column-gap: 2rem;
  row-gap: 1rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
      grid-template-columns: 1fr;
  `}
`;
const DashboardInfoList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: ${({ theme }) => theme.bg1};
  /* border: 1px solid ${({ theme }) => theme.bg3}; */
  border-radius: 0.5rem;

  margin: 0;
  padding: 1rem 2rem;
`;
const DashboardInfoSection = styled.li`
  display: flex;
  flex-direction: column;
`;

const DashboardInfoTitle = styled.h3`
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  font-size: 0.8rem;

  color: ${({ theme }) => theme.text3};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 0.6rem;
  `}
`;
const DashboardInfoDescription = styled.p`
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1rem;
  `}
`;

type DashboardSectionProps = {
  displayInfo: DashboardTokenInfo | null;
};

export function DashboardSection({ displayInfo }: DashboardSectionProps) {
  if (!displayInfo) return <LoadingDashboardSection />;

  const LeftInfos = [
    {
      title: 'MARKET CAPITALIZATION',
      description: displayInfo.capitalization,
    },
    {
      title: 'PROTOCOL OWNED LIQUIDITY',
      description: displayInfo.wethLiquidity,
    },
    { title: 'TOKEN STAKED', description: displayInfo.staked },
  ];

  const rightInfos = [
    {
      title: 'TOTAL SUPPLY',
      description: displayInfo.totalSupply,
    },
    {
      title: 'CIRCULATING SUPPLY',
      description: displayInfo.circulation,
    },
    {
      title: 'TOKEN BURNED',
      description: displayInfo.burned,
    },
  ];

  return (
    <DashboardSectionWrapper>
      <H2>Dashboard</H2>
      <DashboardGrid>
        <DashboardInfoList>
          {LeftInfos.map((info, i) => (
            <DashboardInfoSection key={`dashboard-staking-info-${i}`}>
              <DashboardInfoTitle>{info.title}</DashboardInfoTitle>
              <DashboardInfoDescription>{info.description}</DashboardInfoDescription>
            </DashboardInfoSection>
          ))}
        </DashboardInfoList>
        <DashboardInfoList>
          {rightInfos.map((info, i) => (
            <DashboardInfoSection key={`dashboard-capitaliztion-info-${i}`}>
              <DashboardInfoTitle>{info.title}</DashboardInfoTitle>
              <DashboardInfoDescription>{info.description}</DashboardInfoDescription>
            </DashboardInfoSection>
          ))}
        </DashboardInfoList>
      </DashboardGrid>
    </DashboardSectionWrapper>
  );
}

const reblStakinInfosCount = 3;
const reblCapitalizationInfosCount = 2;

function LoadingDashboardSection() {
  const reblStakingInfos: ReactNode[] = [];
  for (let i = 0; i < reblStakinInfosCount; i++)
    reblStakingInfos.push(
      <DashboardInfoSection key={`dashboard-staking-info-${i}`}>
        <LoadingBox width='4rem' height='0.8rem' margin='0 0 0.5rem' />
        <LoadingBox width='13rem' height='1.7rem' />
      </DashboardInfoSection>,
    );

  const reblCapitalizationInfos: ReactNode[] = [];
  for (let i = 0; i < reblCapitalizationInfosCount; i++)
    reblCapitalizationInfos.push(
      <DashboardInfoSection key={`dashboard-capitaliztion-info-${i}`}>
        <LoadingBox width='4rem' height='0.8rem' margin='0 0 0.5rem' />
        <LoadingBox width='7rem' height='1.7rem' />
      </DashboardInfoSection>,
    );

  return (
    <DashboardSectionWrapper>
      <H2>Dashboard</H2>
      <DashboardGrid>
        <DashboardInfoList>{reblStakingInfos.map((elem) => elem)}</DashboardInfoList>
        <DashboardInfoList>{reblCapitalizationInfos.map((elem) => elem)}</DashboardInfoList>
      </DashboardGrid>
    </DashboardSectionWrapper>
  );
}
