import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { getLibrary } from '../utils/web3React';
import store from '../state';
import 'react-toastify/dist/ReactToastify.css';
import ThemeProvider from './theme';
import { MUIThemeProvider } from './mui-theme';

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <MUIThemeProvider>
          <Provider store={store}>
            <ToastContainer newestOnTop position='bottom-right' closeOnClick={false} limit={3} />
            {children}
            {/* <HelmetProvider>
          </HelmetProvider> */}
          </Provider>
        </MUIThemeProvider>
      </Web3ReactProvider>
    </ThemeProvider>
  );
};

export default Providers;
