import React from 'react';
import { Box, Typography } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';
import { Web3Provider } from '@ethersproject/providers';
import { ITokenContract } from '../../../interfaces/token-contract.interface';
import { CardStakingControl } from './CardStakingControl';
import { IStakingContract } from '../../../state/contract-staking/interfaces/contract.interface';
import { IStakingStateFormatted } from '../../../state/contract-staking/interfaces/data.interface';
import { ITokenStateFormatted } from '../../../state/contract-token/interfaces/data.interface';

interface Props {
  tokenState: ITokenStateFormatted;
  tokenContract: ITokenContract | null;
  stakingState: IStakingStateFormatted;
  stakingContract: IStakingContract | null;
  dispatch: Dispatch<any>;
  account?: string | null;
  library?: Web3Provider;
  loggedIn: boolean;
}

export const BlockStake = ({
  tokenState,
  tokenContract,
  stakingState,
  stakingContract,
  dispatch,
  account,
  library,
  loggedIn,
}: Props): React.ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography
        component='div'
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          component='h1'
          variant='h4'
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            my: 0.5,
          }}
        >
          Your Stakes
        </Typography>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <CardStakingControl
          tokenState={tokenState}
          tokenContract={tokenContract}
          stakingState={stakingState}
          stakingContract={stakingContract}
          dispatch={dispatch}
          account={account}
          library={library}
          loggedIn={loggedIn}
        />
      </Box>
    </Box>
  );
};
