import React from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { PrimaryButton } from 'components/Common/Button';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import WalletModal from '../WalletModal';
import {
  useStakingContract,
  useStakingContractV2,
  useTokenContract,
} from '../../hooks/useContractOld';
import { txPending } from '../../utils/tx-pending';
import { MAX_UINT256, STAKING_CONTRACT_V2_ADDRESS } from '../../config/constants';

interface Props {
  text?: string;
}

const steps = ['Snapshot of V2', 'Withdraw from V2', 'Approve to V3', 'Finalize migrating to V3'];
export default function MigrateModal({ text }: Props) {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [failedStep, setFailedStep] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { account, library } = useActiveWeb3React();
  const stakingContractV2 = useStakingContractV2();
  const stakingContract = useStakingContract();
  const tokenContract = useTokenContract();

  const handleMigrate = async () => {
    try {
      let res;

      const isStarted = await isMigratingStarted();
      const isFinalized = await isMigratingFinilized();
      console.log('isStarted value', isStarted);
      console.log('isFinalized value', isFinalized);

      if (!isStarted && !isFinalized) {
        res = await handleStartMigrating();
        if (!res) {
          toast.error('Error occurred while starting migrating');
          return;
        }
      }

      if (isFinalized) {
        setFailedStep(0);
        toast.error('You have already migrated to V2');
        return;
      }
      handleNext();

      res = await handleUnstakeWithoutReward();
      if (!res) {
        toast.error('Error occurred while unstaking');
        return;
      }
      handleNext();

      res = await handleApprove();
      if (!res) {
        toast.error('Error occurred while approving');
        return;
      }
      handleNext();

      res = await handleFinilizeMigrating();
      if (!res) {
        toast.error('Error occurred while finalizing migrating');
        return;
      }
      toast.success('Migration was successful');
      handleNext();
    } catch (err: any) {
      handleClose();
      console.log(err);
    }
  };

  const isMigratingStarted = async () => {
    if (stakingContractV2 && account) {
      let res;
      try {
        res = await stakingContractV2.startedMigrating(account);
      } catch (err: any) {
        console.log('is migrating started');
        return null;
      }
      return res;
    }
    console.log('contract or account doesnt exist');
    return null;
  };

  const isMigratingFinilized = async () => {
    if (stakingContractV2 && account) {
      let res;
      try {
        res = await stakingContractV2.finalizedMigrating(account);
      } catch (err: any) {
        console.log('is migrating finalized error');
        return null;
      }
      return res;
    }
    console.log('contract or account doesnt exist');
    return null;
  };

  const handleStartMigrating = async () => {
    if (stakingContractV2 && library) {
      let res;
      try {
        res = await stakingContractV2.startMigrating();
      } catch (err: any) {
        toast.error(err.data.message);
        setFailedStep(0);
        console.log('start migrating failed', err);
        return null;
      }
      return res ? txPending(res.hash, library) : null;
    }
    return null;
  };

  const handleUnstakeWithoutReward = async () => {
    if (stakingContract && library) {
      let res;
      try {
        res = await stakingContract.unstakeWithoutReward();
      } catch (err: any) {
        toast.error(err.data.message);
        setFailedStep(1);
        console.log('Unstaking Without Reward failed');
        return null;
      }
      return res ? txPending(res.hash, library) : null;
    }
    return null;
  };

  const handleApprove = async () => {
    if (tokenContract && library) {
      let res;
      try {
        res = await tokenContract.approve(STAKING_CONTRACT_V2_ADDRESS, MAX_UINT256);
      } catch (err: any) {
        toast.error(err.data.message);
        setFailedStep(2);
        console.log('Approving failed with error', err);
        return null;
      }
      return res ? txPending(res.hash, library) : null;
    }
    return null;
  };

  const handleFinilizeMigrating = async () => {
    if (stakingContractV2 && library) {
      let res;
      try {
        res = await stakingContractV2.finalizeMigrating();
      } catch (err: any) {
        toast.error(err.data.message);
        setFailedStep(3);
        console.log('Finilizing migration failed with error', err);
        return null;
      }
      return res ? txPending(res.hash, library) : null;
    }
    return null;
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (account) {
      handleMigrate();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
    setFailedStep(null);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isStepFailed = (index: number) => {
    return failedStep === index;
  };

  return (
    <>
      <PrimaryButton onClick={handleClickOpen}>{text || 'Migrate To V 3.0'}</PrimaryButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title' sx={{ position: 'relative', py: 3, px: 5 }}>
          <Typography variant='h5' component='h5' mr={2} fontWeight={600}>
            REBL Staking Migration
          </Typography>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '8px', right: '8px' }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {account ? (
            <Stepper activeStep={activeStep} orientation='vertical'>
              {steps.map((step, index) => {
                const labelProps: {
                  optional?: React.ReactNode;
                  error?: boolean;
                  StepIconComponent?: React.ElementType;
                } = {};
                if (activeStep === 4 && index === 3) {
                  labelProps.optional = (
                    <Typography variant='caption' color='success'>
                      Done!
                    </Typography>
                  );
                }
                if (activeStep === index && !isStepFailed(index)) {
                  labelProps.StepIconComponent = CircularProgress;
                }
                if (isStepFailed(index)) {
                  labelProps.optional = (
                    <Typography variant='caption' color='error'>
                      Error
                    </Typography>
                  );
                  labelProps.error = true;
                }
                return (
                  <Step
                    key={step}
                    sx={{
                      '& .MuiCircularProgress-root': {
                        width: '1.5rem !important',
                        height: '1.5rem !important',
                      },
                    }}
                  >
                    <StepLabel {...labelProps}>
                      <Typography variant={activeStep === index ? 'h6' : 'body1'} component='h6'>
                        {step}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography variant='h6' component='h6'>
                Connect to migrate to the new version
              </Typography>
              <WalletModal />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
