import { createAction } from '@reduxjs/toolkit';
import { IRank } from './interfaces/contract.interface';
import { IStakingData } from './interfaces/data.interface';

export const fetchStaking = createAction<void>('staking/fetchStaking');
export const fetchStakingSuccess = createAction<{ stakingData: IStakingData }>(
  'staking/fetchStakingSuccess',
);
export const updateTop10StakingSuccess = createAction<{ top10: IRank[] }>(
  'staking/updateTop10StakingSuccess',
);
export const fetchStakingError = createAction<{ error: Error }>('staking/fetchStakingError');
