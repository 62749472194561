import { useEffect } from 'react';
import { useMulticallContract, useStakingContractV2, useTokenContract } from './useContractOld';
import { Status } from '../interfaces/statuses';
import { useAppDispatch } from '../state';
import useActiveWeb3React from './useActiveWeb3React';
import { useStakingStateStatus } from '../state/contract-staking/hooks';
import { useTokenStateStatus } from '../state/contract-token/hooks';
import { useMulticallState, useMulticallStateStatus } from '../state/contract-multicall/hooks';
import { fetchTokenThunk } from '../state/contract-token';
import { fetchStakingThunk } from '../state/contract-staking';
import { fetchMulticallThunk } from '../state/contract-multicall';
import { useNFTStateStatus } from '../state/contract-nft/hooks';
import { fetchNFTThunk } from '../state/contract-nft';
import { useNFTContractV2 } from './useContract';

const useEagerFetchData = () => {
  const { account } = useActiveWeb3React();
  const stakingContractV2 = useStakingContractV2();
  const tokenContract = useTokenContract();
  const multicallContract = useMulticallContract();
  const nftContractV2 = useNFTContractV2();

  const multicallState = useMulticallState();

  const multicallStateStatus = useMulticallStateStatus();
  const nftStateStatus = useNFTStateStatus();
  const tokenStateStatus = useTokenStateStatus();
  const stakingStateStatus = useStakingStateStatus();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (account && tokenStateStatus === Status.INITIAL && tokenContract) {
      dispatch(fetchTokenThunk(tokenContract, account));
    }
  }, [account, tokenStateStatus, tokenContract, dispatch]);

  useEffect(() => {
    if (account && stakingStateStatus === Status.INITIAL && stakingContractV2) {
      dispatch(fetchStakingThunk(stakingContractV2, account));
    }
  }, [account, stakingStateStatus, stakingContractV2, dispatch]);

  useEffect(() => {
    if (account && multicallStateStatus === Status.INITIAL && multicallContract) {
      dispatch(fetchMulticallThunk(multicallContract, account));
    }
  }, [account, multicallStateStatus, multicallContract, dispatch]);

  useEffect(() => {
    if (
      account &&
      multicallStateStatus === Status.SUCCESS &&
      nftStateStatus === Status.INITIAL &&
      nftContractV2
    ) {
      dispatch(fetchNFTThunk(nftContractV2, multicallState.owned, account));
    }
  }, [account, multicallStateStatus, nftStateStatus, multicallState, nftContractV2, dispatch]);
};

export default useEagerFetchData;
