import { createReducer } from '@reduxjs/toolkit';
import { Status } from '../../interfaces/statuses';
import { fetchMulticall, fetchMulticallError, fetchMulticallSuccess } from './actions';
import { IMulticallState } from './interfaces/data.interface';

export const initialState: IMulticallState = {
  owned: [],
  error: null,
  stateStatus: Status.INITIAL,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchMulticall, (state) => ({
      ...state,
      stateStatus: Status.PENDING,
    }))
    .addCase(fetchMulticallSuccess, (state, { payload }) => ({
      ...state,
      ...payload,
      stateStatus: Status.SUCCESS,
    }))
    .addCase(fetchMulticallError, (state, { payload }) => ({
      ...state,
      stateStatus: Status.ERROR,
      error: payload.error,
    })),
);
