import { Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useStakingContractV2, useTokenContract } from '../../../hooks/useContractOld';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import { useAppDispatch } from '../../../state';
import { CardStake } from './CardStake';
import { BlockStake } from './BlockStake';
import { CardTop10 } from './CardTop10';
import { useTokenStateFormatted } from '../../../state/contract-token/hooks';
import { useStakingStateFormatted } from '../../../state/contract-staking/hooks';

export const PageStaking = (): React.ReactElement => {
  const tokenState = useTokenStateFormatted();
  const tokenContract = useTokenContract();

  const stakingState = useStakingStateFormatted();
  const stakingContractV2 = useStakingContractV2();
  const { account, library } = useActiveWeb3React();

  const dispatch = useAppDispatch();
  return (
    <Container>
      <Grid container>
        {/* <Grid item xs={12}>
          <BlockHeader />
        </Grid> */}
        <Grid item xs={12}>
          <Typography
            component='h1'
            variant='h4'
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              textTransform: 'uppercase',
              mb: '5px',
            }}
          >
            Stake
          </Typography>
          <Typography
            component='h2'
            variant='h5'
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            STAKE YOUR $REBL TO EARN REBL DAO NFTS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CardStake
            tokenContract={tokenContract}
            tokenState={tokenState}
            stakingState={stakingState}
            stakingContract={stakingContractV2}
            dispatch={dispatch}
            account={account}
            loggedIn={!!account}
            library={library}
          />
        </Grid>
        <Grid item xs={12}>
          <BlockStake
            tokenState={tokenState}
            tokenContract={tokenContract}
            stakingState={stakingState}
            stakingContract={stakingContractV2}
            dispatch={dispatch}
            account={account}
            library={library}
            loggedIn={!!account}
          />
        </Grid>
        <Grid item xs={12}>
          <CardTop10 rankList={stakingState.top10} />
        </Grid>
        {/* <Grid item xs={12}>
          <BlockTiers />
        </Grid> */}
      </Grid>
    </Container>
  );
};
