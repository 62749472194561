import styled from 'styled-components';

const BasicButton = styled.button`
  position: relative;

  border-radius: 0.5rem;

  padding: 0.6rem 1rem;

  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const PrimaryButton = styled(BasicButton)<{ isLoading?: boolean }>`
  color: ${({ theme }) => theme.primaryText1};

  background-color: ${({ theme }) => theme.primary1};
  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.primary2};
  }
  &:active {
    background-color: ${({ theme }) => theme.primary3};
  }
  &:disabled {
    color: ${({ theme }) => theme.text3};
    background-color: ${({ theme }) => theme.bg3};
  }

  ${({ isLoading: loading, theme }) =>
    loading &&
    `
      color: ${theme.text3} !important;
      background-color: ${theme.bg3} !important;

      &:after {
        content: '';
      }
  `}

  /* loading spinner */
  &:after {
    display: block;

    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);

    width: 2rem;
    height: 2rem;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const SecondaryButton = styled(BasicButton)<{ loading?: boolean }>`
  color: ${({ theme }) => theme.primary1};

  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.primary1};

  &:hover {
    color: ${({ theme }) => theme.primary2};
    border-color: ${({ theme }) => theme.primary2};
  }
  &:active {
    color: ${({ theme }) => theme.primary3};
    border-color: ${({ theme }) => theme.primary3};
  }
  &:disabled {
    color: ${({ theme }) => theme.bg3};
    border-color: ${({ theme }) => theme.bg3};
  }

  ${({ loading, theme }) =>
    loading &&
    `
      color: ${theme.bg3} !important;
      border-color: ${theme.bg3} !important;

      &:after {
        content: '';
      }
  `}

  /* loading spinner */
  &:after {
    display: block;

    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);

    width: 2rem;
    height: 2rem;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
