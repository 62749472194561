import { ChainId, INetworkConfig } from '../../interfaces/connection-config.interface';

export const networksConfig: { [key in ChainId]: INetworkConfig } = {
  [ChainId.BSC_TEST]: {
    rpcUrls: [
      'https://endpoints.omniatech.io/v1/bsc/testnet/public',
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      'https://data-seed-prebsc-1-s2.binance.org:8545/',
    ],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    chainName: 'BSC - Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  [ChainId.BSC_PROD]: {
    rpcUrls: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    blockExplorerUrls: ['https://bscscan.com'],
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  [ChainId.ETH_RINKEBY]: {
    rpcUrls: [''],
    blockExplorerUrls: ['https://rinkeby.etherscan.io'],
    chainName: 'Rinkeby Test Network',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.ETH_PROD]: {
    rpcUrls: ['https://eth.llamarpc.com'],
    blockExplorerUrls: ['https://etherscan.io'],
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [ChainId.XDC_TEST]: {
    rpcUrls: ['https://rpc.apothem.network'],
    blockExplorerUrls: ['https://explorer.apothem.network'],
    chainName: 'XinFin Apothem Testnet',
    nativeCurrency: {
      name: 'XDC',
      symbol: 'TXDC',
      decimals: 18,
    },
  },
  [ChainId.XDC_PROD]: {
    rpcUrls: ['https://rpc.xinfin.network'],
    blockExplorerUrls: ['https://explorer.xinfin.network/'],
    chainName: 'XinFin Network Mainnet',
    nativeCurrency: {
      name: 'XDC',
      symbol: 'XDC',
      decimals: 18,
    },
  },
  [ChainId.GOERLI]: {
    rpcUrls: ['https://goerli.blockpi.network/v1/rpc/public	'],
    blockExplorerUrls: ['https://explorer.xinfin.network/'],
    chainName: 'Goerli',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};
