import { ReactComponent as DashboardSvg } from '../../assets/icons/menu/dashboard.svg';
import { ReactComponent as RewardTiersSvg } from '../../assets/icons/menu/Reward tiers.svg';
import { ReactComponent as StakeSvg } from '../../assets/icons/menu/stake.svg';
import { ReactComponent as MyNftSvg } from '../../assets/icons/menu/My NFT.svg';
import { ReactComponent as DaoPropasalSvg } from '../../assets/icons/menu/DAO Proposals.svg';
import { ReactComponent as DaoForumSvg } from '../../assets/icons/menu/DAO forum.svg';
import { ReactComponent as MarketPlaceSvg } from '../../assets/icons/menu/marketplace.svg';
import { ReactComponent as DocsSvg } from '../../assets/icons/menu/docs.svg';

export const DashboardIcon = () => (
  <DashboardSvg className='bold-stroke' width='1.5rem' height='1.5rem' />
);
export const StakeIcon = () => <StakeSvg className='bold-stroke' width='1.5rem' height='1.5rem' />;
export const MyNftIcon = () => <MyNftSvg width='1.5rem' height='1.5rem' />;
export const NftRewardIcon = () => (
  <RewardTiersSvg className='bold-stroke' width='1.5rem' height='1.5rem' />
);
export const DaoInvestmentIcon = () => <DaoPropasalSvg width='1.5rem' height='1.5rem' />;
export const DaoForumIcon = () => <DaoForumSvg width='1.5rem' height='1.5rem' />;
export const NftMarketIcon = () => <MarketPlaceSvg width='1.5rem' height='1.5rem' />;
export const DocsIcon = () => <DocsSvg />;
