import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { updateVersion } from './global/actions';
import contractMulticall from './contract-multicall/reducer';
import contractNFT from './contract-nft/reducer';
import contractStaking from './contract-staking/reducer';
import contractToken from './contract-token/reducer';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    contractNFT,
    contractStaking,
    contractToken,
    contractMulticall,
  },
});

store.dispatch(updateVersion());

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();

export default store;
