import React from 'react';
import { Box, Typography } from '@mui/material';
import { TextCopy } from '../../UI/TextCopy';
import { addressWithDots } from '../../../utils/addressWithDots';
import { IRank } from '../../../state/contract-staking/interfaces/contract.interface';
import {
  Top10InfoBlock,
  Top10Table,
  Top10TableContainer,
  Top10TD,
  Top10TH,
  Top10TR,
} from './styles';

interface Props {
  rankList: IRank[];
}

export const CardTop10 = ({ rankList }: Props): React.ReactElement => {
  return (
    <Top10InfoBlock>
      <Typography
        component='div'
        sx={{
          textAlign: 'center',
        }}
      >
        <Typography
          component='h1'
          variant='h4'
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            mb: '1.5rem',
          }}
        >
          Top 10 stakers
        </Typography>
      </Typography>
      <Top10TableContainer>
        <Top10Table>
          <thead>
            <tr>
              <Top10TH>Position&nbsp;</Top10TH>
              <Top10TH>Address&nbsp;</Top10TH>
              <Top10TH>$REBL</Top10TH>
              <Top10TH>Weeks</Top10TH>
              <Top10TH>USDT</Top10TH>
            </tr>
          </thead>
          <tbody>
            {rankList.map((rank, index) => (
              <Top10TR
                key={rank.account}
                // sx={{
                //   '&:last-child td, &:last-child th': {
                //     border: 0,
                //   },
                //   '&:nth-of-type(1) .position': {
                //     backgroundColor: '#ffec00',
                //     color: 'black',
                //     fontWeight: 800,
                //   },
                //   '&:nth-of-type(2) .position': {
                //     backgroundColor: '#c0c0c0',
                //     color: 'black',
                //     fontWeight: 800,
                //   },
                //   '&:nth-of-type(3) .position': {
                //     backgroundColor: '#cd7f32',
                //     color: 'black',
                //     fontWeight: 800,
                //   },
                // }}
              >
                <Top10TD>
                  <Box
                    sx={{
                      display: 'inline-block',
                      alignItems: 'center',
                      justifyContent: 'center',
                      justifySelf: 'center',
                      borderRadius: '50%',
                      height: 20,
                      width: 20,
                    }}
                    className='position'
                  >
                    {index + 1}
                  </Box>
                </Top10TD>
                <Top10TD>
                  {/* <TextCopy text={addressWithDots(rank.account)} value={rank.account} /> */}
                  <TextCopy text={rank.account} value={rank.account} />
                </Top10TD>
                <Top10TD>{rank.tokenAmount}</Top10TD>
                <Top10TD>{rank.periodInWeeks}</Top10TD>
                <Top10TD>{rank.multipliedValue}</Top10TD>
              </Top10TR>
            ))}
          </tbody>
        </Top10Table>
      </Top10TableContainer>
    </Top10InfoBlock>
  );
};
