import { createReducer } from '@reduxjs/toolkit';
import { initialCommonState } from '../../interfaces/common-state.interface';
import { Status } from '../../interfaces/statuses';

import { IStakingInfo } from './interfaces/contract.interface';
import { IStakingState } from './interfaces/data.interface';
import {
  fetchStaking,
  fetchStakingError,
  fetchStakingSuccess,
  updateTop10StakingSuccess,
} from './actions';

const initialStakingInfo: IStakingInfo = {
  periodInWeeks: 0,
  rewardUnblockTimestamp: 0,
  tokenAmount: '',
  usdtAmountForReward: '',
};

const initialStakingState: IStakingState = {
  ...initialCommonState,
  stakingInfo: initialStakingInfo,
  rewards: [],
  top10: [],
  top10Status: Status.INITIAL,
};

export default createReducer(initialStakingState, (builder) =>
  builder
    .addCase(fetchStaking, (state) => ({
      ...state,
      stateStatus: Status.PENDING,
    }))
    .addCase(fetchStakingSuccess, (state, { payload }) => ({
      ...state,
      ...payload.stakingData,
      stateStatus: Status.SUCCESS,
    }))
    .addCase(updateTop10StakingSuccess, (state, { payload }) => ({
      ...state,
      top10: payload.top10,
    }))
    .addCase(fetchStakingError, (state, { payload }) => ({
      ...state,
      stateStatus: Status.ERROR,
      error: payload.error,
    })),
);
