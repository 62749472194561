import { useSelector } from 'react-redux';
import { State } from '../types';
import { IMulticallState } from './interfaces/data.interface';
import { Status } from '../../interfaces/statuses';

export const useMulticallState = (): IMulticallState => {
  return useSelector((state: State) => state.contractMulticall);
};

export const useMulticallStateStatus = (): Status => {
  return useSelector((state: State) => state.contractMulticall.stateStatus);
};
