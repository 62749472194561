import { AbstractConnector } from '@web3-react/abstract-connector';
import { networksConfig } from 'config/constants/networks';
import { ChainId } from 'interfaces/connection-config.interface';

const targetChainId: ChainId =
  process.env.REACT_APP_CHAIN_ID === '97' ? ChainId.BSC_TEST : ChainId.BSC_PROD;

export const setupNetwork = async (chainId?: ChainId) => {
  const provider = window.ethereum;
  if (provider && provider.request) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${chainId ?? targetChainId.toString(16)}`,
          },
        ],
      });
      return true;
    } catch (e: any) {
      if (e.code === 4902) {
        const params = [
          {
            ...networksConfig[chainId ?? targetChainId],
            chainId: `0x${chainId ?? targetChainId.toString(16)}`,
          },
        ];
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params,
          });
          return true;
        } catch (addError) {
          console.error('Failed to setup the network in Metamask', e);
          return false;
        }
      }
      console.error('Failed to setup the network in Metamask', e);
      return false;
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined");
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
) => {
  let tokenAdded;
  if (window.ethereum) {
    tokenAdded = await window.ethereum.request?.({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: `/images/tokens/${tokenAddress}.png`,
        },
      },
    });
  }

  return tokenAdded;
};

export const trySwitchingNetwork = async (
  connector: AbstractConnector,
  activate: (connector: AbstractConnector) => Promise<void>,
) => {
  const hasSetup = await setupNetwork();
  if (hasSetup) {
    console.info('Switched network');

    activate(connector as AbstractConnector);
    return true;
  }

  return false;
};
