import { Telegram } from '@mui/icons-material';
import { ReactNode } from 'react';
import { Twitter, Youtube } from 'react-feather';
import { ReactComponent as MediumSVG } from '../../assets/icons/medium.svg';
import { ReactComponent as OpenSeaSVG } from '../../assets/icons/open-sea.svg';

export type SocialLinkInfo = {
  href: string;
  icon: ReactNode;
};

export const socialLinks: SocialLinkInfo[] = [
  {
    icon: <Telegram />,
    href: 'https://t.me/RebellionProtocol',
  },
  {
    icon: <Twitter />,
    href: 'https://twitter.com/RebellionToken',
  },
  {
    icon: <Youtube />,
    href: 'https://www.youtube.com/channel/UC-PX0mqwIGWiNEnPRbDbk7Q',
  },
  {
    icon: <MediumSVG viewBox='0 0 24 24' />,
    href: 'https://medium.com/@RebellionProtocol',
  },
  {
    icon: <OpenSeaSVG viewBox='0 0 22 20' />,
    href: 'https://opensea.io/collection/rebellion-protocol-1',
  },
];
